const { setReferenceResults } = require('./jade-common')
const { openGetSearchCommon } = require('./common-open-get-search')
const { handleResStatus } = require('./utils-handle-errors')

//  Memo: Set searchObj before action for tags last search inside results page
async function actionOpenGetSearchRefsAd ({ state, dispatch, commit }, { fileAdId }) {
  const nameValParent = 'complexSearchRef'
  console.log('actionOpenGetSearchRefsAd > fileAdId: ', fileAdId)
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  // reset refResults
  await commit(actNm, { nameValParent: 'searchResults', nameVal: 'refResults', val: [] })

  await commit(actNm, { nameValParent: 'isLastSearchWithDirectSearch', nameVal: 'lastSearchRef', val: false })
  try {
    const response = await openGetSearchCommon({ state, commit, nameValParent, fileAdId })
    console.log('actionOpenGetSearchRefsAd -> response', response)
    await commit(actNm, { nameValParent, nameVal: 'lastJade', val: fileAdId })
    await commit(actNm, { nameValParent, nameVal: 'currentJade', val: '' })
    await commit('set_nestedOne', { nameValParent: 'sessionMetrics', nameVal: 'nbSearchRef', val: state.sessionMetrics.nbSearchRef + 1 })

    handleResStatus({
      dispatch,
      nameAction: 'set_nestedOne',
      nameValParent: 'responseSearch',
      commit,
      response,
      noSeqKey: true
    })

    if (response && response.data) {
      await setReferenceResults(commit, response.data)
    }
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    if (fileAdId === state[nameValParent].lastGetValue) {
      await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
      await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    }
    console.error(err)
  }
}

export {
  actionOpenGetSearchRefsAd
}
