const { asyncAxiosCommon } = require('./axios-common')
const { actionCreateNewUser } = require('../services/ruby-post-user')
const { setNewUserObjFromState } = require('../utils/set-new-user-obj')
const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS

function setShowModalUserDeleted ({ response, commit }) {
  const isString = response && response.data && typeof response.data === 'string'
  if (response && response.status === 200 && isString && response.data.includes('CODE-DEL')) {
    let author = 'votre administrateur'
    if (response.data.includes('CODE-DEL-SEQ')) { author = 'un membre de l\'équipe de modération Sequallia' }
    commit('set_oneEffect', { nameVal: 'userDelAuthor', val: author })
    commit('set_oneEffect', { nameVal: 'showModalUserDeleted', val: true })
  }
}

async function getSequalliaKey ({ state, commit, value, userToken, isNewSeqKey }) {
  try {
    const url = `${BASE_URL_API}/iron/users`
    console.debug('getSequalliaKey -> url', url)
    const seqKeyCurrent = state.gettingSequalliaKey.seqKeyCurrent
    const config = {
      method: 'GET',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'user-token-id': state.user.uTkId,
        'seq-key-current': seqKeyCurrent.toString()
      },
      //  Need to have new url with seqKeyCurrent to restart cache private
      params: { id: value, key: seqKeyCurrent },
      url
    }
    if (isNewSeqKey) {
      const newSeqKey = seqKeyCurrent + 1
      console.log('getSequalliaKey > newSeqKey', newSeqKey)
      await commit('set_nestedOne', { nameValParent: 'gettingSequalliaKey', nameVal: 'seqKeyCurrent', val: newSeqKey })
      // isNewSeqKey is to manage cache state if event connexion (link or creation user)
      config.headers['seq-key-current'] = newSeqKey.toString()
    }
    const response = await asyncAxiosCommon(config)
    console.log('getSequalliaKey response', response)
    setShowModalUserDeleted({ response, commit })

    if (response && response.data && response.data.sequalliaKey) {
      //  Set data for user
      await commit('set_userFromSequalliaKey', { data: response.data })
    }
    if (response && response.data) { return response }
    throw new Error('Response undefined from getSequalliaKey')
  } catch (err) {
    console.error(`getSequalliaKey: ${err.message || err}`)
    throw err
  }
}

async function actionGetSequalliaKey ({ state, commit, vm, isNewSeqKey }) {
  const value = state.user.uTkId
  console.log('actionGetSequalliaKey: ', value)
  console.log('actionGetSequalliaKey > isNewSeqKey: ', isNewSeqKey)
  const nameValParent = 'gettingSequalliaKey'
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    //  get userToken
    const userToken = await vm.$getToken()
    //  get and commit sequalliaKey
    let response = await getSequalliaKey({ state, commit, value, userToken, isNewSeqKey })
    console.log('actionGetSequalliaKey -> response:', response)

    //  Create user if needed before try again
    if (response && response.data && response.data.needUserCreation) {
      console.log('response.data.needUserCreation', response.data.needUserCreation)
      const newUserObj = setNewUserObjFromState(state)
      await actionCreateNewUser({ state, commit, vm }, { value: newUserObj })
      response = await getSequalliaKey({ state, commit, value, userToken, isNewSeqKey })
    }

    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    //  Bellow used for plugin $getTokenAndSequalliaKey
    if (response && response.data && response.data.sequalliaKey) {
      const sequalliaKey = response.data.sequalliaKey
      return { userToken, sequalliaKey }
    }
    return { userToken }
  } catch (err) {
    console.error(err)
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
  }
}

export { actionGetSequalliaKey }
