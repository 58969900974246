
async function commitMinusCredit ({ state, commit, nameCdtUsed }) {
  const equivCdtName = {
    cdtUsedSearchBk: 'cdtSh',
    cdtUsedSearchRf: 'cdtSh',
    cdtUsedViewBk: 'cdtVwBk',
    cdtUsedViewRf: 'cdtVwRf'
  }
  const nameVal = equivCdtName[nameCdtUsed]
  if (!nameVal) { return }
  const nameValParent = 'user'
  const currentVal = state[nameValParent][nameVal]
  const val = currentVal - 1
  console.log(`commitMinusCredit -> new ${nameVal}:`, val)
  await commit('set_nestedOne', { nameValParent, nameVal, val })
}

async function commitCreditUsed ({ state, commit, nameCdtUsed }) {
  const nameValParent = 'sessionMetrics'
  const nameVal = nameCdtUsed
  const currentVal = state[nameValParent][nameVal]
  const val = currentVal + 1
  console.log(`commitCreditUsed -> new ${nameVal}:`, val)
  await commit('set_nestedOne', { nameValParent, nameVal, val })
  await commitMinusCredit({ state, commit, nameCdtUsed })
}

export {
  commitCreditUsed
}
