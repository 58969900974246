const { asyncAxiosCommon } = require('./axios-common')
const {
  commitBookCurrent,
  setNewBookCurrent
} = require('./common-get-book')
const {
  handleResStatus
} = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

async function getBookByFileName ({ state, commit, bookId, fileAdId }) {
  try {
    const fileNm = `ad-book-details-${bookId}-${fileAdId}`
    const url = `${BASE_URL_API}/diamond/files`
    console.debug('getBookByFileName -> url', url)
    const config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { name: fileNm },
      timeout: 15000,
      url
    }
    console.log('getBookByFileName > config', config)
    await commitBookCurrent({ commit, val: bookId, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getBookByFileName > response', response)
    //  Verify if it is the last clicked event to show it
    if (response && bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getBookByFileName: ${err.message || err}`)
    throw err
  }
}

async function actionOpenGetBookAd ({ state, dispatch, commit }, { bookId, fileAdId }) {
  console.log('actionOpenGetBookAd: ', bookId)
  await commitBookCurrent({ commit, nameVal: 'isPending', val: true })
  await commitBookCurrent({ commit, nameVal: 'isError', val: false })
  try {
    const response = await getBookByFileName({ state, commit, bookId, fileAdId })
    console.log('actionOpenGetBookAd -> response', response)

    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })

    await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
    //  need set_countChangeBookOrRefCurrent after isPending false
    if (response && response.data) {
      await setNewBookCurrent(commit, response.data)
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.log('this.$store.state.bookCurrent', state.bookCurrent)
  } catch (err) {
    if (bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      await commitBookCurrent({ commit, nameVal: 'isError', val: true })
      await commitBookCurrent({ commit, nameVal: 'bookDataCurrent', val: {} })
      await commitBookCurrent({ commit, val: '', nameVal: 'bookIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.error(err)
  }
}

export { actionOpenGetBookAd }
