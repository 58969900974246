import Vue from 'vue'
import Vuex from 'vuex'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'
import App from './App.vue'
import router from './router'
import storeObj from './store'
import VueLazyload from 'vue-lazyload'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { MyPluginAuthMsal } from './plugins/auth-msal'
import { MyPluginAuthSequalliaKey } from './plugins/auth-sequallia-key'
import alertHello from './mixins/alert-hello'

import { localStorageAvailable } from './utils/local-storage'
import { filterLastJadopen } from './utils/set-general-history'
import {
  filterCapitalize,
  filterThousands,
  filterUppercase
} from './utils/filters'
require('@/assets/font-faces-mVRRoeZ2YExqqyXMHZw1q.css')

Vue.config.productionTip = false

// vuex-router-sync https://www.npmjs.com/package/vuex-router-sync
// const unsync = sync(store, router)

// Register all base-component
const requireComponent = require.context('./components/basics', false, /base-[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')))
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.filter('capitalize', function (val) { return filterCapitalize(val) })
Vue.filter('uppercase', function (val) { return filterUppercase(val) })
Vue.filter('thousands', function (val) { return filterThousands(val) })

Vue.use(Vuex)
const store = new Vuex.Store(storeObj)

Vue.use(MyPluginAuthMsal, { store })
Vue.use(MyPluginAuthSequalliaKey, { store })
Vue.use(VueLazyload, {
  // error: 'dist/error.png',
  // loading: 'dist/loading.gif',
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueSocialSharing)

new Vue({
  router,
  store,
  mixins: [
    alertHello
  ],
  created () {
    this.loadLocalStorage()
    this.$startLogin()
  },
  methods: {
    loadLocalStorage () {
      if (localStorageAvailable()) {
        this.setCookiesSelection()
        this.setCookiesGeneral()
      }
    },
    setCookiesSelection () {
      const baseName = 'sequallia.selection.list'
      const nameValParent = 'selectionList'
      this.$store.dispatch('actionGetItemsArrayFromLocalStorage', {
        nameValArray: ['listReferences', 'listBooks'],
        baseName,
        nameValParent
      })
      this.$store.dispatch('actionGetItemsBooleanFromLocalStorage', {
        nameValBoolean: ['isFullReferences', 'isFullBooks'],
        baseName,
        nameValParent
      })
    },
    setCookiesGeneral () {
      const baseName = 'sequallia.general.history'
      const nameValParent = 'genHistory'
      this.$store.dispatch('actionGetItemsArrayFromLocalStorage', {
        nameValArray: ['lastJadopen'],
        baseName,
        nameValParent,
        arrayFilter: filterLastJadopen
      })
      console.log('lastJadopen:', this.$store.state.genHistory.lastJadopen)
    }
  },
  render: h => h(App)
}).$mount('#app')
