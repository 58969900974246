export default ({
  props: {
    setDocumentOverflow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      scrollPositionBeforeFixed: 0
    }
  },
  mounted () {
    if (this.setDocumentOverflow) { this.setHtmlOverflowHidden() }
  },
  destroyed () {
    if (this.setDocumentOverflow) { this.unsetHtmlOverflowHidden() }
  },
  methods: {
    setHtmlOverflowHidden () {
      this.scrollPositionBeforeFixed = document.documentElement.scrollTop
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.position = 'fixed'
      document.documentElement.style.top = `-${this.scrollPositionBeforeFixed}px`
      console.debug('document.documentElement.scrollTop', document.documentElement.scrollTop)
    },
    unsetHtmlOverflowHidden () {
      document.documentElement.style.overflow = 'auto'
      document.documentElement.style.position = ''
      document.documentElement.style.top = ''
      window.scrollTo(0, parseInt(this.scrollPositionBeforeFixed || '0', 10))
    }
  }
})
