const { poseidonObj } = require('../utils/utils-poseidon')
const { asyncAxiosCommon } = require('./axios-common')
const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS

async function createNewUser ({ state, value, userToken, uTkId }) {
  console.log('createNewUser userToken', userToken)
  try {
    //  Value is coming from user input
    const poseidonVal = poseidonObj(value)
    const url = `${BASE_URL_API}/ruby/users/`
    console.debug('createNewUser -> url', url)
    const seqKeyCurrent = state.gettingSequalliaKey.seqKeyCurrent
    const config = {
      method: 'post',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'user-token-id': uTkId,
        'seq-key-current': seqKeyCurrent.toString()
      },
      data: poseidonVal,
      url
    }
    console.debug('createNewUser > config', config)
    const data = await asyncAxiosCommon(config)
    return data
  } catch (err) {
    console.error(`createNewUser: ${err.message || err}`)
    throw err
  }
}

async function actionCreateNewUser ({ state, commit, vm }, { value }) {
  //  Return if already created
  if (state.creatingUser.isUserRecentlyCreated) { return }
  console.log('actionCreateNewUser: ', value)
  const nameValParent = 'creatingUser'
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    const uTkId = value.uTkId
    const userToken = await vm.$getToken()
    let response
    try {
      response = await createNewUser({ state, value, userToken, uTkId })
    } catch (err) {
      const status = err.response && err.response.status
      const errString = err.toString()
      //  Stop creating new user if already exists
      if (status === 409 || (errString && errString.includes('user already exists'))) {
        await commit(actNm, { nameValParent, nameVal: 'isUserRecentlyCreated', val: true })
        const isNewSeqKey = true
        await vm.$getTokenAndSequalliaKey(isNewSeqKey)
      } else {
        throw err
      }
    }
    console.log('actionCreateNewUser > response:', response)
    if (response && response.data && response.data.userId) {
      const isNewSeqKey = true
      await vm.$getTokenAndSequalliaKey(isNewSeqKey)
      await commit(actNm, { nameValParent, nameVal: 'isUserRecentlyCreated', val: true })
      if (!state.effect.showModalNewUserOnce) {
        await commit('set_oneEffect', { nameVal: 'showModalNewUserOnce', val: true })
        await commit('set_oneEffect', { nameVal: 'showModalNewUser', val: true })
      }
    }
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    console.error(err)
  }
}

export { actionCreateNewUser }
