function setNewUserObjFromState (state) {
  //  Create new user
  const user = state.user
  const newUserObj = {
    uTkId: user.uTkId,
    uFirstNm: user.uFirstNm,
    uLastNm: user.uLastNm,
    uTel: user.uTel,
    uMail: user.uMail
  }
  if (user.uTitle) { newUserObj.uTitle = user.uTitle }
  if (user.myTmNm) { newUserObj.mySocNm = user.myTmNm }
  return newUserObj
}

export {
  setNewUserObjFromState
}
