async function commitBookCurrent ({ commit, val, nameVal }) {
  //  lastGetValue is used to cancel previous request
  await commit('set_nestedOne', {
    val,
    nameValParent: 'bookCurrent',
    nameVal
  })
  console.debug(`commitBookCurrent > ${nameVal}`, val)
}

async function setNewBookCurrent (commit, bookObj) {
  if (!bookObj) { throw new Error('setNewBookCurrent > Error, bookObj undefined') }
  console.debug('setNewBookCurrent -> bookObj', bookObj)
  await commitBookCurrent({ commit, val: bookObj.bookId, nameVal: 'bookIdCurrent' })
  await commitBookCurrent({ commit, val: bookObj, nameVal: 'bookDataCurrent' })
  const name = bookObj.allNames ? (bookObj.allNames.mktNm || bookObj.allNames.bkNm) : ''
  await commitBookCurrent({ commit, val: name, nameVal: 'bkNmCurrent' })
  // Ads need to have bkTown & bkZipC & bkLand inside responses
  if (bookObj.bkTown) { await commitBookCurrent({ commit, val: bookObj.bkTown, nameVal: 'bkTownCurrent' }) }
  if (bookObj.bkZipC) { await commitBookCurrent({ commit, val: bookObj.bkZipC, nameVal: 'bkZipCCurrent' }) }
  if (bookObj.bkLand) { await commitBookCurrent({ commit, val: bookObj.bkLand, nameVal: 'bkLandCurrent' }) }
}

export {
  commitBookCurrent,
  setNewBookCurrent
}
