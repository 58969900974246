const axios = require('axios').default

const axiosRetry = require('axios-retry')
axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    console.log('axiosRetry > error', error)
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED' || error.config.method.toLowerCase() === 'get'
  }
})

async function asyncAxiosCommon (config) {
  try {
    console.log('asyncAxiosCommon > config:', config)
    const response = await axios(config)
    console.debug('asyncAxiosCommon > response:', response)
    if (response) { return response }
    throw new Error('asyncAxiosCommon > Response axios undefined')
  } catch (err) {
    console.log('asyncAxiosCommon error > err:', err)
    console.log('asyncAxiosCommon error > err.config:', err.config)
    //  Intercept 'Network Error' for too many requests
    const errString = err.toString()
    if (err && typeof err === 'object' && errString.includes('Network Error')) {
      err.message = 'asyncAxiosCommon > Network Error, maybe too many requests or server off'
      console.error(err.message)
    }
    //  In case of quota or rate limit reached, no error
    if (errString.includes('code 403')) { return { status: 403 } }
    if (errString.includes('code 429')) { return { status: 429 } }
    throw err
  }
}

export { asyncAxiosCommon }
