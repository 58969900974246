const { commitCreditUsed } = require('./common-credit-used')
const {
  getJadeCommon,
  setReferenceResults
} = require('./jade-common')
const { setLastJadopen } = require('../utils/set-general-history')
const {
  handleIfNoCreditsSearch,
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')

async function actionSearchRefs ({ state, dispatch, commit, vm }, {
  value,
  nameValParent,
  isJadopen
}) {
  const endUrl = nameValParent === 'directSearchRef' ? 'bar/refs' : 'refs'

  console.log('actionSearchRefs: ', value)
  console.log('actionSearchRefs > nameValParent:', nameValParent)
  console.log('actionSearchRefs > isJadopen:', isJadopen)
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  // reset refResults
  await commit(actNm, { nameValParent: 'searchResults', nameVal: 'refResults', val: [] })
  const boolean = nameValParent === 'directSearchRef'

  await commit(actNm, { nameValParent: 'isLastSearchWithDirectSearch', nameVal: 'lastSearchRef', val: boolean })
  try {
    const { userToken } = isJadopen ? {} : await vm.$getTokenAndSequalliaKey()
    const response = await getJadeCommon({
      state,
      commit,
      value,
      userToken,
      nameValParent,
      endUrl,
      isJadopen
    })
    console.log('actionSearchRefs -> response', response)

    await commit(actNm, { nameValParent, nameVal: 'lastJade', val: value })
    await commit(actNm, { nameValParent, nameVal: 'currentJade', val: '' })

    //  Check sequallia key
    let isValidSeqKey
    if (!isJadopen) {
      isValidSeqKey = isSequalliaKeyValid(response)
      handleIfNoCreditsSearch({ state, dispatch, commit, response })
      await commitCreditUsed({ state, commit, nameCdtUsed: 'cdtUsedSearchRf' })
    }
    handleResStatus({
      dispatch,
      nameAction: 'set_nestedOne',
      nameValParent: 'responseSearch',
      commit,
      response,
      noSeqKey: isJadopen
    })

    if ((isValidSeqKey || isJadopen) && response && response.data) {
      const isValidJadopen = isJadopen && response && response.status === 200
      const arrayResults = isJadopen && !isValidJadopen ? [] : response.data
      await setReferenceResults(commit, arrayResults)
      if (isJadopen) { setLastJadopen({ commit, state }) }
    }
    await commit('set_nestedOne', { nameValParent: 'sessionMetrics', nameVal: 'nbSearchRef', val: state.sessionMetrics.nbSearchRef + 1 })
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    if (value === state[nameValParent].lastGetValue) {
      await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
      await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    }
    console.error(err)
  }
}

async function actionRefsDirectSearch ({ state, dispatch, commit, vm }, { value, isJadopen }) {
  const nameValParent = 'directSearchRef'
  return actionSearchRefs({ state, dispatch, commit, vm }, { value, nameValParent, isJadopen })
}

async function actionRefsComplexSearch ({ state, dispatch, commit, vm }, { value, isJadopen }) {
  const nameValParent = 'complexSearchRef'
  return actionSearchRefs({ state, dispatch, commit, vm }, { value, nameValParent, isJadopen })
}

export {
  actionRefsDirectSearch,
  actionRefsComplexSearch
}
