<template>
  <div>
    <div
      class="s-form-select"
      :class="{'s-base-color-focus-vs': focusActive}"
      @keypress.13.prevent
    >
      <div :class="{'s-base-hide-chevron-vs': !isChevron}">
        <div
          v-if="isLabel"
          class="s-select-label"
        >
          <!-- :for="idInput" -->
          <label
            class="s-text-select-label"
            @click.prevent="$refs.select.$refs.search.focus"
          >
            {{ labelHolder }}
          </label>
        </div>
        <div :class="{'s-dropdown-hide-input': !isInputVisible}">
          <div :class="{'s-select-text-red': isError}">
            <v-select
              :id="idInput"
              ref="select"
              placeholder=""
              :class="[isMin? '' : 's-not-dropdown-menu']"
              :select-on-tab="false"
              :taggable="isTagabble"
              v-bind="$attrs"
              @keypress.13.prevent
              @search:focus="focusActive = true"
              @search:blur="focusActive = false"
              v-on="listeners"
            >
              <span slot="no-options">...</span>
            </v-select>
          </div>
        </div>
      </div>
      <div
        v-if="deselect"
        :class="[isFill ? 's-mask-open-indicator' : 's-mask-open-indicator-no']"
      >
        <label
          v-if="isFill"
          :class="[isLeftInput ? 's-deselect-label-v-thinner' : 's-deselect-label-v']"
          title="Supprimer"
          :for="idDeselect"
        >
          <button
            :id="idDeselect"
            ref="select-deselect"
            class="s-deselect-btn"
            tabindex="-1"
            @click.prevent="$emit('deselect')"
          >
            <BaseIconDeselect />
          </button>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import VueSelect from 'vue-select'

export default {
  name: 'BaseSelect',
  components: {
    'v-select': VueSelect
  },
  // Render wrappers transparents
  inheritAttrs: false,
  props: {
    idInput: {
      type: String,
      required: true
    },
    labelHolder: {
      type: String,
      required: true
    },
    isFill: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isMin: {
      type: Boolean,
      default: false
    },
    deselect: {
      type: Boolean,
      default: true
    },
    isTagabble: {
      type: Boolean,
      default: true
    },
    isChevron: {
      type: Boolean,
      default: false
    },
    isLabel: {
      type: Boolean,
      default: true
    },
    isInputVisible: {
      type: Boolean,
      default: true
    },
    isLeftInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focusActive: false
    }
  },
  computed: {
    idDeselect () {
      return `${this.idInput}-deselect`
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event =>
          this.$emit('input', event)
      }
    }
  }
}
</script>
