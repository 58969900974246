const { asyncAxiosCommon } = require('./axios-common')
const {
  commitBookCurrent,
  setNewBookCurrent
} = require('./common-get-book')
const {
  handleResStatus
} = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

async function getBookTemp ({ state, commit, bookId, myKeyOne, myKeyTwo }) {
  try {
    const url = `${BASE_URL_API}/emerald/books`
    console.debug('getBookTemp -> url', url)
    const config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN,
        'my-key-one': myKeyOne,
        'my-key-two': myKeyTwo
      },
      params: { id: bookId },
      timeout: 15000,
      url
    }
    console.log('getBookTemp > config', config)
    await commitBookCurrent({ commit, val: bookId, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getBookTemp > response', response)
    //  Verify if it is the last clicked event to show it
    if (response && bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getBookTemp: ${err.message || err}`)
    throw err
  }
}

async function actionGetBookTemp ({ state, dispatch, commit }, { bookId, myKeyOne, myKeyTwo }) {
  await commitBookCurrent({ commit, nameVal: 'isPending', val: true })
  await commitBookCurrent({ commit, nameVal: 'isError', val: false })
  try {
    const response = await getBookTemp({ state, commit, bookId, myKeyOne, myKeyTwo })
    console.log('actionGetBookTemp -> response', response)

    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })

    await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
    //  need set_countChangeBookOrRefCurrent after isPending false
    if (response && response.data) {
      const dataBook = response.data
      // isDataTempBook is used to set WrapperLastViewed
      dataBook.isDataTempBook = true
      await setNewBookCurrent(commit, dataBook)
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
  } catch (err) {
    if (bookId && bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      await commitBookCurrent({ commit, nameVal: 'isError', val: true })
      await commitBookCurrent({ commit, nameVal: 'bookDataCurrent', val: {} })
      await commitBookCurrent({ commit, val: '', nameVal: 'bookIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.error(err)
  }
}

export { actionGetBookTemp }
