const { asyncAxiosCommon } = require('./axios-common')
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS
const {
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')
const { commonGetBillInfos } = require('./rock-get-books')

async function getSessionPortalId ({ state, userId, myBkId, userToken }) {
  try {
    console.log('getSessionPortalId -> userToken', userToken)
    const url = process.env.VUE_APP_URL_ROCK_PORTAL
    console.debug('getSessionPortalId -> url', url)
    const config = {
      method: 'get',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': userId,
        'my-book-id': myBkId
      },
      params: {
        id: userId
      },
      url
    }
    console.debug('getSessionPortalId > config', config)
    const response = await asyncAxiosCommon(config)
    return response
  } catch (err) {
    console.error(`getSessionPortalId: ${err.message || err}`)
    throw err
  }
}

async function createSessionPortal (sessionPortalUrl) {
  window.open(sessionPortalUrl, '_self')
}

async function actionGetPortal ({ state, dispatch, commit, vm }) {
  const userId = state.user.userId
  const myBkId = state.user.myBkId
  //  Need userId & myBkId
  //  User must be logined
  if (!userId || !myBkId) { return console.error('Need userId, myBkId') }

  const nameValParent = 'gettingPortal'
  const actNm = 'set_nestedOne'
  dispatch('set_oneEffect', { nameVal: 'showModalWaitingPortal', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    const { userToken } = await vm.$getTokenAndSequalliaKey()

    //  Get bill infos to know if other users from my book have subscriptions
    await commonGetBillInfos({ state, dispatch, commit, myBkId, userId, userToken })

    const response = await getSessionPortalId({ state, userId, myBkId, userToken })
    const sessionPortalUrl = response && response.data && response.data.sessionPortalUrl
    const noPortalUrl = response && response.data && response.data.noPortalUrl
    console.log('actionGetPortal > response:', response)
    console.log('actionGetPortal -> sessionPortalUrl:', sessionPortalUrl)
    if (noPortalUrl) {
      await commit(actNm, { nameValParent, nameVal: 'noPortalUrl', val: true })
      await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
      return
    }
    if (!sessionPortalUrl) {
      throw new Error('Need sessionPortalUrl')
    }
    handleResStatus({
      dispatch,
      response,
      noSeqKey: true
    })

    const isValid = isSequalliaKeyValid(response)
    if (isValid) { createSessionPortal(sessionPortalUrl) }
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    console.error(err)
  }
}

export { actionGetPortal }
