const keysMutatedSequalliaKey = [
  'sequalliaKey',
  'userId',
  'uTkId',
  'hasNoBk',
  'myTmNm',
  'uFirstNm',
  'uLastNm',
  'uTitle',
  'isTitan',
  'premNm',
  'conNm',
  'cdtVwRf',
  'cdtVwBk',
  'cdtSh',
  'uRole',
  'myBkId',
  'myBkNm',
  'myBkTown',
  'myBkZipC',
  'myBkLand',
  'mySocSiren',
  'stripeCusId'
]

export {
  keysMutatedSequalliaKey
}
