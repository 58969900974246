function suppressBraces (str) {
  const withoutBraces = str.replace(/\{|\}/g, '')
  return withoutBraces
}

function suppressHugeWhiteSpaces (str) {
  const withoutWhiteSpaces = str.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
  const noWhiteSpacesBothEnds = withoutWhiteSpaces.trim()
  return noWhiteSpacesBothEnds
}

function suppressWhiteSpacesAfterOrBeforeParenthesis (str) {
  let withoutWhiteSpaces = str.replace(/\(\s/g, '(')
  withoutWhiteSpaces = str.replace(/\s\)/g, ')')
  return withoutWhiteSpaces
}

function suppressSpecialCharactersExceptedTheseOnes (str) {
  const withoutSpecials = str.replace(/[^-¨=+,.²…³_–•ç!?î#%&()*;:<>’'‘«»“”"@èéò/àäëñœâêîôûùïì\w\s]/gi, '')
  return withoutSpecials
}

function suppressWords (str) {
  str = str.replace('onclick', '')
  return str
}

function poseidonString (str) {
  console.assert(typeof str === 'string', 'poseidonString > it is not a string')
  console.log('poseidonString -> str', str)
  if (str === undefined || str === null || str === '') { return '' }
  if (typeof (str) === 'object') { return '' }
  console.debug('poseidonString before: ', str)
  console.assert(str !== undefined && str !== null, 'str undefined')
  str = suppressBraces(str)
  str = suppressHugeWhiteSpaces(str)
  str = suppressWhiteSpacesAfterOrBeforeParenthesis(str)
  str = suppressSpecialCharactersExceptedTheseOnes(str)
  str = suppressWords(str)
  console.debug('poseidonString after: ', str)
  console.assert(str !== undefined && str !== null, 'str undefined')
  return str
}

function poseidonArray (array) {
  for (const [index, value] of array.entries()) {
    if (typeof value === 'string') {
      array[index] = poseidonString(value)
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      array[index] = poseidonObj(value)
    }
    if (typeof value === 'object' && Array.isArray(value)) {
      array[index] = poseidonArray(value)
    }
  }
  return array
}

function poseidonObj (obj) {
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'string') {
      obj[key] = poseidonString(obj[key])
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      obj[key] = poseidonObj(obj[key])
    }
    if (typeof obj[key] === 'object' && Array.isArray(obj[key])) {
      obj[key] = poseidonArray(obj[key])
    }
  }
  return obj
}

export {
  suppressHugeWhiteSpaces,
  poseidonString,
  poseidonObj
}
