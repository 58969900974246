function localStorageAvailable () {
  const storage = window && window.localStorage
  console.log('localStorageAvailable -> storage:', storage)
  const test = '__sequallia.storage.test__'
  if (!storage) { return }
  storage.setItem(test, test)
  const val = storage.getItem(test)
  storage.removeItem(test)
  if (val && val === test) { return true }
}

function getItemLocalStorage (nameItem, typeOfItem, valDefault) {
  try {
    const valItem = window.localStorage.getItem(nameItem)
    if (!valItem || typeof valItem !== 'string') {
      throw new Error('valItem is not string')
    }
    const parsed = JSON.parse(valItem)
    if (!parsed) {
      throw new Error('parsed undefined')
    }
    const typeOfParsed = typeof parsed
    const val = typeOfItem !== typeOfParsed ? valDefault || null : parsed
    return val
  } catch (err) {
    console.error(`getItemLocalStorage > ${err.message}`)
  }
}

async function actionGetItemFromLocalStorage ({ commit }, {
  nameItem,
  nameValParent,
  nameVal,
  typeOf,
  valDefault,
  arrayFilter
}) {
  console.assert(nameItem, 'nameItem undefined')
  if (window && window.localStorage && window.localStorage.getItem(nameItem)) {
    console.debug('actionGetItemFromLocalStorage -> ok')
    try {
      let val = getItemLocalStorage(nameItem, typeOf, valDefault)
      if (arrayFilter) { val = arrayFilter(val) }
      await commit('set_nestedOne', { val, nameVal, nameValParent })
    } catch (err) {
      console.error(`actionGetItemFromLocalStorage > ${err.message || err}`)
      window.localStorage.removeItem(nameItem)
    }
  }
}

async function actionGetItemsArrayFromLocalStorage ({ commit }, {
  nameValArray,
  baseName,
  nameValParent,
  arrayFilter
}) {
  for (const nameVal of nameValArray) {
    const nameItem = `${baseName}.${nameVal}`
    //  Important: typeOf is object, not array
    const typeOf = 'object'
    const valDefault = []
    await actionGetItemFromLocalStorage({ commit }, {
      nameItem,
      nameValParent,
      nameVal,
      typeOf,
      valDefault,
      arrayFilter
    })
  }
}

async function actionGetItemsBooleanFromLocalStorage ({ commit }, { nameValBoolean, baseName, nameValParent }) {
  for (const nameVal of nameValBoolean) {
    const nameItem = `${baseName}.${nameVal}`
    const typeOf = 'boolean'
    const valDefault = false
    await actionGetItemFromLocalStorage({ commit }, {
      nameItem,
      nameValParent,
      nameVal,
      typeOf,
      valDefault
    })
  }
}

function setItemLocalStorage (nameItem, item) {
  console.log('setItemLocalStorage -> item', item)
  console.log('setItemLocalStorage -> nameItem', nameItem)
  try {
    if (!window || !window.localStorage) { return }
    console.assert(nameItem, 'nameItem undefined')
    console.assert(item !== undefined, 'item undefined')
    const stringified = JSON.stringify(item)
    console.log('setItemLocalStorage -> stringified', stringified)
    const storage = window && window.localStorage
    storage.setItem(nameItem, stringified)
    console.log(`setItemLocalStorage > ${nameItem} > stringified: `, stringified)
    console.log('setItemLocalStorage > localStorage: ', window.localStorage)
  } catch (err) {
    console.error(`setItemLocalStorage > ${err.message || err}`)
    throw err
  }
}

export {
  localStorageAvailable,
  actionGetItemFromLocalStorage,
  actionGetItemsArrayFromLocalStorage,
  actionGetItemsBooleanFromLocalStorage,
  getItemLocalStorage,
  setItemLocalStorage
}
