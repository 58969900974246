async function commitRefCurrent ({ commit, val, nameVal }) {
  //  lastGetValue is used to cancel previous request
  await commit('set_nestedOne', {
    val,
    nameValParent: 'refCurrent',
    nameVal
  })
  console.debug(`commitRefCurrent > ${nameVal}`, val)
}

async function setNewRefCurrent (commit, refObj) {
  console.log('setNewRefCurrent > refObj', refObj)
  if (!refObj) { throw new Error('setNewRefCurrent > Error, refObj undefined') }
  await commitRefCurrent({ commit, val: refObj.refId, nameVal: 'refIdCurrent' })
  if (refObj.essSht) {
    await commitRefCurrent({ commit, val: refObj, nameVal: 'refDataCurrent' })
    await commitRefCurrent({ commit, val: refObj.essSht.refNm, nameVal: 'refNmCurrent' })
  }
}

export {
  commitRefCurrent,
  setNewRefCurrent
}
