<template>
  <div class="zzcmr-btn-form-tag-preview">
    <div
      v-if="lines && lines.length > 0"
      class="s-form-margin"
      :class="{'zzcmr-btn-clickable': isClickable}"
    >
      <div
        :class="[isLink ? 'zzcmr-btn-form-tag-link' : 'zzcmr-btn-form-tag']"
        @click="$emit('click')"
      >
        <p
          v-for="(line, $lineIndex) in lines"
          :key="`btn-form-tag-line-${$lineIndex}`"
          class="zzcmr-btn-form-tag-text"
        >
          {{ line }}
          <span
            v-if="lines.length > $lineIndex + 1"
            class="zzcmr-btn-form-tag-pellet"
          />
        </p>
        <div
          v-if="!isPreview"
          class="zzcmr-btn-form-tag-wrapper-icon"
        >
          <div
            v-if="isOrderTop"
            ref="tag-order-top"
            class="zzcmr-btn-form-tag-btn-icon"
            title="Déplacer"
            @click.stop="$emit('order-top')"
          >
            <BaseIconCaretUp class="zzcmr-btn-form-tag-icon-caret-up" />
          </div>
          <div
            v-if="isEdit"
            ref="tag-edit"
            class="zzcmr-btn-form-tag-btn-icon"
            title="Modifier"
            @click.stop="$emit('edit')"
          >
            <BaseIconEdit class="zzcmr-btn-form-tag-icon" />
          </div>
          <div
            v-if="isDelete"
            ref="tag-delete"
            class="zzcmr-btn-form-tag-btn-icon"
            title="Supprimer"
            @click.stop="$emit('delete')"
          >
            <BaseIconTrash class="zzcmr-btn-form-tag-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    one: {
      type: [String, Number],
      default: ''
    },
    two: {
      type: [String, Number],
      default: ''
    },
    three: {
      type: [String, Number],
      default: ''
    },
    four: {
      type: [String, Number],
      default: ''
    },
    five: {
      type: [String, Number],
      default: ''
    },
    six: {
      type: [String, Number],
      default: ''
    },
    seven: {
      type: [String, Number],
      default: ''
    },
    eight: {
      type: [String, Number],
      default: ''
    },
    nine: {
      type: [String, Number],
      default: ''
    },
    ten: {
      type: [String, Number],
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    isOrderTop: {
      type: Boolean,
      default: true
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: false
    },
    isClickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lines () {
      return [this.one, this.two, this.three, this.four, this.five, this.six, this.seven, this.eight, this.nine, this.ten].filter(line => line !== '')
    }
  }
}

</script>

<style lang="scss">

@import '../../styles/components-global/_modal-reference-btn-form.scss';

</style>
