const { asyncAxiosCommon } = require('./axios-common')
const {
  commitRefCurrent,
  setNewRefCurrent
} = require('./common-get-ref')
const {
  handleResStatus
} = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

async function getRefDirectly ({ state, commit, refId, bookId }) {
  try {
    const url = `${BASE_URL_API}/onyx/refs`
    console.debug('getRefDirectly -> url', url)
    let config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { refId, bookId },
      timeout: 15000,
      url
    }
    console.log('getRefDirectly config', config)
    await commitRefCurrent({ commit, val: refId, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getRefDirectly response', response)
    //  Verify if it is the last clicked event to show it
    if (response && refId === state.refCurrent.lastGetValue) {
      await commitRefCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getRefDirectly: ${err.message || err}`)
    throw err
  }
}

async function actionGetRefDirectly ({ state, dispatch, commit }, { refId, bookId }) {
  console.log('actionGetRefDirectly, refId:', refId)
  await commitRefCurrent({ commit, nameVal: 'isPending', val: true })
  await commitRefCurrent({ commit, nameVal: 'isError', val: false })
  try {
    const response = await getRefDirectly({ state, commit, refId, bookId })
    console.log('actionGetRefDirectly > response:', response)

    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })

    await commitRefCurrent({ commit, nameVal: 'isPending', val: false })
    //  need set_countChangeBookOrRefCurrent after isPending false
    if (response && response.data) {
      await setNewRefCurrent(commit, response.data)
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'refCurrent', nameVal: 'countChangeRefCurrent' })
    }
    console.log('this.$store.state.refCurrent', state.refCurrent)
  } catch (err) {
    if (refId && refId === state.refCurrent.lastGetValue) {
      await commitRefCurrent({ commit, nameVal: 'isPending', val: false })
      await commitRefCurrent({ commit, nameVal: 'isError', val: true })
      await commitRefCurrent({ commit, nameVal: 'refDataCurrent', val: {} })
      await commitRefCurrent({ commit, val: '', nameVal: 'refIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'refCurrent', nameVal: 'countChangeRefCurrent' })
    }
    console.error(err)
  }
}

export { actionGetRefDirectly }
