
const sortResultsFree = 'Tri par pertinence'

const arrayOptionsSortBooksPremium = [
  sortResultsFree,
  //  Attention, same strings must be used in component SelectSort
  'CA décroissant',
  'CA croissant'
]

const arrayOptionsSortRefsPremium = [
  sortResultsFree,
  //  Attention, same strings must be used in component SelectSort
  'Surface décroissante',
  'Surface croissante',
  'Année de fin décroissante',
  'Année de fin croissante'
]

function setFreeSortOptions (options) {
  return options.map((el) => { return el === sortResultsFree ? el : `${el} (Premium)` })
}

const arrayOptionsSortBooksFree = setFreeSortOptions(arrayOptionsSortBooksPremium)
const arrayOptionsSortRefsFree = setFreeSortOptions(arrayOptionsSortRefsPremium)

export {
  arrayOptionsSortBooksPremium,
  arrayOptionsSortRefsPremium,
  arrayOptionsSortBooksFree,
  arrayOptionsSortRefsFree,
  sortResultsFree
}
