const { asyncAxiosCommon } = require('./axios-common')
const { commitCreditUsed } = require('./common-credit-used')
const {
  commitRefCurrent,
  setNewRefCurrent
} = require('./common-get-ref')
const {
  handleIfNoCreditsView,
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')
const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS

function setHeadersViewRefBookId (config, options) {
  if (!options || !options.vRfBkId) { console.error('getRefById > Need options & vRfBkId') }
  config.headers['view-rfbk-id'] = options.vRfBkId
  return config
}

async function getRefById ({ state, commit, value, userToken, isOneRefOfUs, nameApi, options }) {
  try {
    console.log('getRefById > isOneRefOfUs', isOneRefOfUs)
    const url = `${BASE_URL_API}/${nameApi}/refs`

    console.debug('getRefById -> url', url)
    let config = {
      method: 'GET',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': state.user.userId
        //  Add vRfBkId later
      },
      params: {
        id: value
      },
      url
    }
    if (state.user.myBkId) { config.headers['my-book-id'] = state.user.myBkId }
    config = setHeadersViewRefBookId(config, options)
    console.log('getRefById config', config)
    await commitRefCurrent({ commit, val: value, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getRefById response', response)
    //  Verify if it is the last clicked event to show it
    if (response && value === state.refCurrent.lastGetValue) {
      await commitRefCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getRefById: ${err.message || err}`)
    throw err
  }
}

async function actionGetRefCurrent ({ state, dispatch, commit, vm }, { vRfId, vRfBkId, isOneRefOfUs }) {
  console.log('actionGetRefCurrent > isOneRefOfUs:', isOneRefOfUs)
  console.log('actionGetRefCurrent:', vRfId)
  await commitRefCurrent({ commit, nameVal: 'isPending', val: true })
  await commitRefCurrent({ commit, nameVal: 'isError', val: false })
  try {
    const { userToken } = await vm.$getTokenAndSequalliaKey()
    const options = { vRfBkId }

    let nameApi = 'opal'
    if ((state.bookCurrent.bookIdCurrent === state.user.myBkId && isOneRefOfUs) || state.user.isTitan) { nameApi = 'myopal' }

    const response = await getRefById({ state, commit, value: vRfId, userToken, isOneRefOfUs, nameApi, options })
    console.log('actionGetRefCurrent > response:', response)

    //  Check sequallia key
    const isValid = isSequalliaKeyValid(response)
    console.log('isValid', isValid)
    handleIfNoCreditsView({ state, dispatch, commit, response, typeOfAction: 'view-refs' })
    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })
    if (nameApi === 'opal') { await commitCreditUsed({ state, commit, nameCdtUsed: 'cdtUsedViewRf' }) }

    await commitRefCurrent({ commit, nameVal: 'isPending', val: false })
    //  need set_countChangeBookOrRefCurrent after isPending false
    if (isValid && response && response.data) {
      await setNewRefCurrent(commit, response.data)
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'refCurrent', nameVal: 'countChangeRefCurrent' })
    }
    console.log('this.$store.state.refCurrent', state.refCurrent)
  } catch (err) {
    if (vRfId === state.refCurrent.lastGetValue) {
      await commitRefCurrent({ commit, nameVal: 'isPending', val: false })
      await commitRefCurrent({ commit, nameVal: 'isError', val: true })
      await commitRefCurrent({ commit, nameVal: 'refDataCurrent', val: {} })
      await commitRefCurrent({ commit, val: '', nameVal: 'refIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'refCurrent', nameVal: 'countChangeRefCurrent' })
    }
    console.error(err)
  }
}

export { actionGetRefCurrent }
