<template>
  <div>
    <div
      class="s-container"
      @click="closeEverywhere()"
    >
      <transition
        name="s-modal"
        appear
      >
        <div class="s-modal-mask">
          <div class="s-modal-wrapper-global">
            <div class="s-modal-container-center">
              <button
                class="s-close-out-modal"
                @click.prevent="$emit('close')"
              />
              <div class="s-page-center">
                <div class="s-navbar-modal-fix">
                  <label
                    :for="idBtnClose"
                    class="s-label-close-btn"
                  >
                    <button
                      :id="idBtnClose"
                      class="s-close"
                      @click.prevent="$emit('close')"
                    >
                      <BaseIcon height="24px">
                        <BaseIconClose />
                      </BaseIcon>
                    </button>
                  </label>
                  <div class="s-title">
                    {{ title }}
                  </div>
                </div>
                <div class="s-modal-body">
                  <slot name="content" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import closeModalWithEsc from '../../mixins/close-modal-with-esc'
import htmlOverflowHidden from '../../mixins/html-overflow-hidden'

export default {
  name: 'BaseModalForm',
  mixins: [
    closeModalWithEsc,
    htmlOverflowHidden
  ],
  props: {
    title: {
      type: String,
      default: ''
    },
    idBtnClose: {
      type: String,
      default: 'zzimd-close'
    },
    isCloseEverywhere: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeEverywhere () {
      if (this.isCloseEverywhere) { this.$emit('close') }
    }
  }
}

</script>
