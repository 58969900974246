<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="height"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
  >
    <g
      :fill="iconColor"
      :stroke="iconColor"
    >
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
