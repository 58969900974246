const { poseidonString } = require('../utils/utils-poseidon')
const { asyncAxiosCommon } = require('./axios-common')
const { sortResultsFree } = require('../utils/const-sort-results-options')

const API_SUBSCRIPTION_MEMBERS = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS
const API_SUBSCRIPTION_JADOPEN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_JADOPEN_UNKNOWN
const BASE_URL_API = process.env.VUE_APP_BASE_URL_API

async function setCardsResults (commit, arrayResults = [], nameVal) {
  const nameValParent = 'searchResults'
  await commit('set_nestedOne', { nameValParent, nameVal, val: arrayResults })
}

async function resetSortResultsType (commit, nameVal) {
  await commit('zzmvss/set_sortCurrent', { nameVal, val: sortResultsFree })
}

async function setSocietyResults (commit, array) {
  await setCardsResults(commit, array, 'socResults')
  await resetSortResultsType(commit, 'sortResultsBooks')
}

async function setReferenceResults (commit, arrayResults) {
  await setCardsResults(commit, arrayResults, 'refResults')
  await resetSortResultsType(commit, 'sortResultsRefs')
}

async function getJadeCommon ({
  state,
  commit,
  value,
  userToken,
  nameValParent,
  endUrl,
  isJadopen
}) {
  try {
    //  Value is coming from user input
    const poseidonVal = poseidonString(value).toString()
    //  lower string if it is a direct search / upper for code search
    const search = nameValParent.includes('directSearch')
      ? poseidonVal.toLowerCase()
      : poseidonVal.toUpperCase()
    const nameJade = isJadopen ? 'jadopen' : 'jade'
    const subscriptionKey = isJadopen ? API_SUBSCRIPTION_JADOPEN : API_SUBSCRIPTION_MEMBERS
    const headers = { 'Ocp-Apim-Subscription-Key': subscriptionKey }
    if (!isJadopen) {
      headers.Authorization = 'Bearer ' + userToken
      headers['sequallia-key'] = state.user.sequalliaKey
      headers['user-id'] = state.user.userId
    }
    const url = `${BASE_URL_API}/${nameJade}/${endUrl}`
    const config = {
      method: 'GET',
      timeout: 15000,
      headers,
      params: { search },
      url
    }
    console.log('getJadeCommon > config:', config)
    if (state.user.myBkId) { config.headers['my-book-id'] = state.user.myBkId }
    const actNm = 'set_nestedOne'
    await commit(actNm, { nameValParent, nameVal: 'lastGetValue', val: value })
    const response = await asyncAxiosCommon(config)
    console.log('getJadeCommon > response', response)
    //  Verify if it is the last clicked event to show it
    if (response && value === state[nameValParent].lastGetValue) {
      await commit(actNm, { nameValParent, nameVal: 'lastGetValue', val: null })
      return response
    }
  } catch (err) {
    console.error(`getJadeCommon: ${err.message || err}`)
    throw err
  }
}

export {
  getJadeCommon,
  setReferenceResults,
  setSocietyResults
}
