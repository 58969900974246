const { asyncAxiosCommon } = require('./axios-common')
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS
const {
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')

async function setBillInfos (commit, bookObj) {
  if (!bookObj) { throw new Error('setBillInfos > Error, book undefined') }
  console.log('setBillInfos -> bookObj', bookObj)
  const actNm = 'set_nestedOne'
  const billArray = ['bkNm', 'bkAdr', 'bkTown', 'bkZipC', 'bkLand', 'socSiren']
  for (const nameVal of billArray) {
    const val = bookObj[nameVal] || ''
    await commit(actNm, { val, nameValParent: 'billInfos', nameVal })
  }
  const parentArray = ['payConnect', 'paySearch']
  const payArray = ['subNm', 'userId', 'billMail']
  for (const parent of parentArray) {
    for (const nameVal of payArray) {
      const val = (bookObj[parent] && bookObj[parent][nameVal]) || ''
      await commit(actNm, { val, nameValParent: parent, nameVal })
    }
  }
}

async function getBillInfos ({ state, value, userId, userToken }) {
  try {
    console.log('getBillInfos -> userToken', userToken)
    const url = process.env.VUE_APP_URL_ROCK_BOOKS
    console.debug('getBillInfos -> url', url)
    const config = {
      method: 'get',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': userId,
        'my-book-id': value
      },
      params: {
        id: value
      },
      url
    }
    console.debug('getBillInfos > config', config)
    const response = await asyncAxiosCommon(config)
    return response
  } catch (err) {
    console.error(`getBillInfos: ${err.message || err}`)
    throw err
  }
}

//  Used by actionGetBillInfos & actionGetPortal
async function commonGetBillInfos ({ state, dispatch, commit, myBkId, userId, userToken }) {
  try {
    const response = await getBillInfos({ state, value: myBkId, userId, userToken })
    console.log('commonGetBillInfos -> response', response)
    //  Check sequallia key
    const isValid = isSequalliaKeyValid(response)
    handleResStatus({
      dispatch,
      response,
      noSeqKey: true
    })
    if (isValid && response && response.data) {
      await setBillInfos(commit, response.data)
    }
  } catch (err) {
    console.error(`commonGetBillInfos: ${err.message || err}`)
    throw err
  }
}

async function actionGetBillInfos ({ state, dispatch, commit, vm }) {
  const userId = state.user.userId
  const myBkId = state.user.myBkId
  //  Need userId & myBkId
  //  User must be logined
  if (!userId || !myBkId) { return console.error('Need userId, myBkId') }
  const nameValParent = 'gettingBillInfos'
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    const { userToken } = await vm.$getTokenAndSequalliaKey()
    await commonGetBillInfos({ state, dispatch, commit, myBkId, userId, userToken })
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    console.error(`actionGetBillInfos: ${err.message || err}`)
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
  }
}

export {
  actionGetBillInfos,
  commonGetBillInfos
}
