<template>
  <div>
    <div class="s-form-margin">
      <div :class="{'zzcmr-btn-form-with-plus': isPlus}">
        <button
          ref="button"
          class="s-btn-form-classic"
          :class="[isIcon || isCenter ? 'zzcmr-btn-form-icon' : 'zzcmr-btn-form']"
          v-bind="$attrs"
          @keypress.13.prevent
          v-on="listeners"
        >
          <BaseIconPlus
            v-if="isPlus"
            ref="icon-plus"
            class="zzcmr-btn-form-icon-plus"
          />
          <slot
            v-if="isSlot"
            name="content"
          />
          <span
            v-else
            ref="form-text"
          >{{ text }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseBtnForm',
  // Render wrappers transparents
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: ''
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    isCenter: {
      type: Boolean,
      default: false
    },
    isPlus: {
      type: Boolean,
      default: false
    },
    isSlot: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        button: event =>
          this.$emit('button', event)
      }
    }
  }
}

</script>
