const { asyncAxiosCommon } = require('./axios-common')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

//  For ads search books and refs
async function openGetSearchCommon ({ state, commit, nameValParent, fileAdId }) {
  try {
    const typeSearchAd = nameValParent === 'complexSearchSoc' ? 'books' : 'refs'
    const fileNm = `ad-search-${typeSearchAd}-${fileAdId}`
    const url = `${BASE_URL_API}/diamond/files`
    console.debug('openGetSearchCommon -> url', url)
    const config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { name: fileNm },
      timeout: 15000,
      url
    }
    console.log('openGetSearchCommon > config', config)
    const actNm = 'set_nestedOne'
    await commit(actNm, { nameValParent, nameVal: 'lastGetValue', val: fileAdId })
    const response = await asyncAxiosCommon(config)
    console.log('openGetSearchCommon > response', response)
    //  Verify if it is the last clicked event to show it
    if (response && fileAdId === state[nameValParent].lastGetValue) {
      await commit(actNm, { nameValParent, nameVal: 'lastGetValue', val: null })
      return response
    }
  } catch (err) {
    console.error(`openGetSearchCommon: ${err.message || err}`)
    throw err
  }
}

export {
  openGetSearchCommon
}
