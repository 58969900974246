<template>
  <button
    :class="[zIndexHigher ? 's-close-out-modal-center' : 's-close-out-modal']"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'BaseCloseOutModal',
  props: {
    zIndexHigher: {
      type: Boolean
    }
  }
}
</script>
