import * as msal from '@azure/msal-browser'
import {
  myMSALObj,
  myMSALObjPassword,
  loginRequest,
  tokenRequest,
  silentRequest,
  checkTokenIss
} from '../utils/auth-msal-config'
import jwtDecode from 'jwt-decode'
import {
  localStorageAvailable,
  getItemLocalStorage,
  setItemLocalStorage
} from '../utils/local-storage'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add 'isEdge' to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const isEdge = msedge > 0
const isIE = msie > 0 || msie11 > 0 || isEdge

let signInType = isIE ? 'loginPopup' : 'loginPopup'
let accountId = ''
let accountObj = null

export const MyPluginAuthMsal = {
  install: function (Vue, { store }) {

    function setUserHasToken (boolean) {
      store.dispatch('set_userData', { val: boolean, nameVal: 'hasToken' })
      if (boolean) { setUserFailedToken(false) }
    }

    function setUserFailedToken (boolean) {
      store.dispatch('set_userData', { val: boolean, nameVal: 'failedToken' })
      if (boolean) { setUserHasToken(false) }
    }

    function setUserAccount () {
      const currentAccounts = myMSALObj.getAllAccounts()
      console.log('setUserAccount -> currentAccounts', currentAccounts)
      if (!currentAccounts || currentAccounts.length === 0) {
        // No user signed in
        accountObj = null
      } else if (currentAccounts.length > 1) {
        // More than one user signed in, take last logged
        accountId = currentAccounts[0].homeAccountId
        accountObj = currentAccounts[0]
        return accountObj
      } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId
        accountObj = currentAccounts[0]
        return accountObj
      }
    }

    function authHandleResponse (tokenResponse) {
      console.log('authHandleResponse -> tokenResponse', tokenResponse)
      if (tokenResponse !== null) {
        let accessToken
        if (typeof tokenResponse === 'object') {
          accountObj = tokenResponse.account
          accountId = accountObj && accountObj.homeAccountId
          accessToken = tokenResponse.accessToken
        }
        if (typeof tokenResponse === 'string') {
          accessToken = tokenResponse
        }
        setUserHasToken(true)
        showWelcomeMessage(accessToken)
        // seeProfileRedirect()
      } else {
        accountObj = setUserAccount()
        setUserFailedToken(true)
      }
      console.log('authHandleResponse -> accountObj:', accountObj)
    }

    // Register Callbacks for Redirect flow
    myMSALObj.handleRedirectPromise()
      .then(authHandleResponse)
      .catch(error => {
        console.log(error)
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          console.log('password policy')
          myMSALObjPassword.loginRedirect(loginRequest)
        }
      })

    function getTokenDecoded (token) {
      if (token) {
        const tokenDecoded = jwtDecode(token)
        console.log('getTokenDecoded -> tokenDecoded:', tokenDecoded)
        if (!checkTokenIss(tokenDecoded)) { return }
        return tokenDecoded
      }
    }

    function showWelcomeMessage (accessToken) {
      console.log('showWelcomeMessage > accessToken:', accessToken)
      if (!accessToken) {
        setUserFailedToken(true)
        return
      }
      setUserHasToken(true)
      const userObj = getTokenDecoded(accessToken)
      console.log('showWelcomeMessage > userObj:', userObj)
      store.dispatch('set_userFromToken', userObj)
      //  get sequallia key or create user
      store.dispatch('actionGetSequalliaKey')
    }

    async function getTokenRedirect (tokenRequest) {
      console.log('getTokenRedirect -> tokenRequest', tokenRequest)
      const account = myMSALObj.getAccountByHomeId(accountId)
      if (!account) {
        console.log('getTokenRedirect > need account for silent request')
        return
      }
      silentRequest.account = account
      const tokenResponse = await myMSALObj.acquireTokenSilent(silentRequest).catch(async err => {
        console.log('getTokenRedirect > silent token acquisition fails during acquiring token using redirect')
        if (err instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log('acquiring token using redirect')
          const res = await myMSALObj.acquireTokenRedirect(tokenRequest)
          return res
        } else {
          console.error(err)
        }
      })
      console.log('getTokenRedirect -> tokenResponse', tokenResponse)
      const accessToken = tokenResponse.accessToken
      return accessToken
    }

    async function getTokenPopup (tokenRequest) {
      console.log('getTokenPopup -> tokenRequest', tokenRequest)
      const account = myMSALObj.getAccountByHomeId(accountId)
      if (!account) {
        console.log('getTokenPopup > need account for silent request')
        return
      }
      silentRequest.account = account
      const tokenResponse = await myMSALObj.acquireTokenSilent(silentRequest).catch(async err => {
        console.log('getTokenRedirect > silent token acquisition fails during acquiring token using popup')
        if (err instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log('acquiring token using popup')
          const res = await myMSALObj.acquireTokenPopup(tokenRequest)
            .catch(error => {
              console.error(error)
            })
          return res
        } else {
          console.error(err)
        }
      })
      console.log('getTokenRedirect -> tokenResponse', tokenResponse)
      const accessToken = tokenResponse.accessToken
      return accessToken
    }

    //  Return undefined if never set clickedOnConnect before for this navigator
    function getClickedOnConnect () {
      if (localStorageAvailable()) {
        const nameItem = 'sequallia.general.history.clickedOnConnect'
        const clickedOnConnect = getItemLocalStorage(nameItem, 'boolean')
        console.log('getClickedOnConnect -> clickedOnConnect', clickedOnConnect)
        return clickedOnConnect
      }
    }

    function setClickedOnConnect (boolean) {
      if (localStorageAvailable()) {
        const baseName = 'sequallia.general.history'
        setItemLocalStorage(`${baseName}.clickedOnConnect`, boolean)
      }
    }

    Vue.prototype.$signOut = function () {
      setClickedOnConnect(false)
      const logoutRequest = {
        account: myMSALObj.getAccountByHomeId(accountId)
      }
      myMSALObj.logoutRedirect(logoutRequest)
      setUserHasToken(false)
    }

    Vue.prototype.$signIn = async function () {
      setClickedOnConnect(true)
      console.log('$signIn > signInType', signInType)
      //  Force loginRedirect during signIn
      signInType = 'loginRedirect'
      if (signInType === 'loginPopup') {
        const res = await myMSALObj.loginPopup(loginRequest)
          .then(authHandleResponse)
          .catch(error => {
            console.log(error)
            console.log('error.errorMessage:', error.errorMessage)
            if (error.errorMessage.indexOf('AADB2C90118') > -1) {
              console.log('password policy')
              myMSALObjPassword.loginPopup(loginRequest)
                .catch(function (error) {
                  console.log('handleRedirectOrPopup loginPopup error:', error)
                })
            }
            //  Redirect if error with popup but not for cancel popup
            if (!error.errorMessage.includes('cancelled the flow')) {
              myMSALObj.loginRedirect(loginRequest)
            }
          })
        return res
      }
      return myMSALObj.loginRedirect(loginRequest)
    }

    Vue.prototype.$getToken = async function () {
      console.log('$getToken > signInType', signInType)
      const responseToken = signInType === 'loginPopup'
        ? await getTokenPopup(tokenRequest)
        : await getTokenRedirect(tokenRequest)
      console.log('$getToken > responseToken', responseToken)
      return responseToken
    }

    //  Login during creation if user was logged once in the past for this navigator
    Vue.prototype.$startLogin = async function () {
      accountObj = setUserAccount()
      const clickedOnConnect = getClickedOnConnect()
      console.debug('startLogin accountObj', accountObj)
      console.debug('startLogin clickedOnConnect', clickedOnConnect)
      if (accountObj && clickedOnConnect) {
        setClickedOnConnect(false)
        await getTokenRedirect(tokenRequest).then(tokenResponse => {
          if (tokenResponse) { setClickedOnConnect(true) }
          authHandleResponse(tokenResponse)
          console.log('startLogin tokenResponse', tokenResponse)
        })
      }
    }

  }
}
