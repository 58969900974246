<template>
  <label
    class="s-span-radio-div"
    :class="classLabel"
  >
    <input
      v-model="radioButtonValue"
      type="radio"
      :value="label"
      :name="name"
      :class="classInput"
    >
    <div class="s-label-radio">
      <span class="s-label-text-radio">
        {{ label }}
      </span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseRadio',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    classInput: {
      type: String,
      default: 's-input-radio'
    },
    classLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    radioButtonValue: {
      get () {
        return this.value
      },
      set () {
        this.$emit('change', this.label)
      }
    }
  }
}
</script>
