import { loadStripe } from '@stripe/stripe-js/pure'
const { asyncAxiosCommon } = require('./axios-common')
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS
const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
const {
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')

async function postSessionCheckout ({ state, userId, myBkId, priceId, userToken, billInfos }) {
  try {
    console.log('postSessionCheckout -> userToken', userToken)
    const url = process.env.VUE_APP_URL_ROCK_CHECKOUT
    console.debug('postSessionCheckout -> url', url)
    const config = {
      method: 'post',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': userId,
        'my-book-id': myBkId
      },
      data: {
        priceId,
        ...billInfos
      },
      url
    }
    console.debug('postSessionCheckout > config', config)
    const data = await asyncAxiosCommon(config)
    return data
  } catch (err) {
    console.error(`postSessionCheckout: ${err.message || err}`)
    throw err
  }
}

async function createSessionCheckout (sessionCheckoutId) {
  try {
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
    const { error } = stripe.redirectToCheckout({ sessionId: sessionCheckoutId })
    if (error) { return error.message }
  } catch (err) {
    console.error(`createSessionCheckout: ${err.message || err}`)
    throw err
  }
}

async function actionOpenCheckout ({ state, dispatch, commit, vm }, { priceId }) {
  const userId = state.user.userId
  const myBkId = state.user.myBkId
  //  Need userId & myBkId & priceId
  //  User must be logIn and have a book
  if (!userId || !myBkId || !priceId) { return console.error('Need userId, myBkId & priceId') }

  const nameValParent = 'creatingCheckout'
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    //  Get session Id
    const userToken = await vm.$getToken()
    const billInfos = state.billInfos
    const response = await postSessionCheckout({ state, userId, myBkId, priceId, userToken, billInfos })
    const sessionCheckoutId = response && response.data && response.data.sessionCheckoutId
    console.log('postSessionCheckout > response:', response)
    console.log('postSessionCheckout -> sessionCheckoutId:', sessionCheckoutId)
    if (!sessionCheckoutId) {
      throw new Error('Need sessionCheckoutId')
    }
    handleResStatus({
      dispatch,
      response,
      noSeqKey: true
    })

    const isValid = isSequalliaKeyValid(response)
    if (isValid) {
      const error = await createSessionCheckout(sessionCheckoutId)
      console.log('actionOpenCheckout -> error', error)
      if (error) {
        await commit(actNm, { nameValParent, nameVal: 'msgErrorCheckout', val: error })
        throw new Error(error)
      }
    }
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    console.error(err)
  }
}

export { actionOpenCheckout }
