import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const ViewAwesomeTools = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewAwesomeTools.vue')
const ViewComingSoon = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewComingSoon.vue')
const ViewEndStripe = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewEndStripe.vue')
const ViewHome = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewHome.vue')
const ViewSearchSocieties = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewSearchSocieties.vue')
const ViewMyRulesMails = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewMyRulesMails.vue')
const ViewMySelection = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewMySelection.vue')
const ViewPrices = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewPrices.vue')
const ViewSociety = () => import(/* webpackChunkName: "chunk-entry-views" */ /* webpackPreload: true */ '../views/ViewSociety.vue')
// const GainsFree = () => import(/* webpackChunkName: "GainsFree" */ /* webpackPrefetch: true */'../views/ViewGainsFree.vue')

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'view-home',
      path: '/',
      component: ViewHome,
      props: true
    },
    {
      name: 'view-home-ref-ad',
      path: '/_:id',
      component: ViewHome,
      props: true
    },
    {
      name: 'view-society',
      path: '/societe',
      component: ViewSociety,
      props: true
    },
    {
      name: 'view-society-temp',
      path: '/ma-societe',
      component: ViewSociety
    },
    // When props is set to true, the route.params will be set as the component props
    {
      name: 'view-society-direct',
      path: '/societe/:bkPath',
      component: ViewSociety,
      props: true
    },
    {
      name: 'view-search-societies',
      path: '/recherche',
      component: ViewSearchSocieties,
      props: true
    },
    {
      name: 'view-search-societies-ref-ad',
      path: '/recherche_:id',
      component: ViewSearchSocieties,
      props: true
    },
    {
      name: 'view-my-rules-mails',
      path: '/gerer-mes-droits',
      component: ViewMyRulesMails
    },
    {
      name: 'view-my-selection',
      path: '/ma-selection',
      component: ViewMySelection,
      props: true
    },
    {
      name: 'view-my-selection-ref-ad',
      path: '/ma-selection_:id',
      component: ViewMySelection,
      props: true
    },
    {
      name: 'view-coming-soon',
      path: '/tres-prochainement',
      component: ViewComingSoon,
      props: true
    },
    {
      name: 'view-stripe-success',
      path: '/subscription/success',
      component: ViewEndStripe
    },
    {
      name: 'view-stripe-cancel',
      path: '/subscription/cancel',
      component: ViewEndStripe
    },
    {
      name: 'view-prices',
      path: '/offres',
      component: ViewPrices,
      props: true
    },
    {
      name: 'awesome-tools',
      path: '/boite-a-outils-batiment',
      component: ViewAwesomeTools
    },
    //  For history mode
    {
      name: 'other-routes',
      path: '*',
      component: ViewHome,
      props: true
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ /* webpackPrefetch: true */ './views/About.vue')
    // }
  ]
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
})

export default router
