import {
  localStorageAvailable,
  setItemLocalStorage
} from '../utils/local-storage'
import { dateNowSecondes } from '../utils/utils-various'

function filterLastJadopen (lastJadopen) {
  const dateNow = dateNowSecondes()
  //  Keep only last 7 days search
  const oneWeekSec = 7 * 24 * 3600
  const newArray = lastJadopen.filter(el => parseInt(el) > (dateNow - oneWeekSec))
  return newArray
}

function setLastJadopen ({ commit, state }) {
  const lastJadopen = state.genHistory.lastJadopen
  const dateNow = dateNowSecondes()
  const newArray = filterLastJadopen(lastJadopen)
  newArray.push(dateNow)
  commit('set_nestedOne', {
    nameValParent: 'genHistory',
    nameVal: 'lastJadopen',
    val: newArray
  })
  if (localStorageAvailable()) {
    const baseName = 'sequallia.general.history'
    setItemLocalStorage(`${baseName}.lastJadopen`, newArray)
  }
}

export {
  filterLastJadopen,
  setLastJadopen
}
