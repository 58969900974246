const { asyncAxiosCommon } = require('./axios-common')
const { commitCreditUsed } = require('./common-credit-used')
const {
  commitBookCurrent,
  setNewBookCurrent
} = require('./common-get-book')
const {
  handleIfNoCreditsView,
  handleResStatus,
  isSequalliaKeyValid
} = require('./utils-handle-errors')
const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS

async function getBookById ({ state, commit, value, userToken, nameApi }) {
  try {
    const url = `${BASE_URL_API}/${nameApi}/books`

    console.debug('getBookById -> url', url)
    const config = {
      method: 'GET',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': state.user.userId
      },
      params: {
        id: value
      },
      url
    }
    console.log('getBookById > config', config)
    if (state.user.myBkId) { config.headers['my-book-id'] = state.user.myBkId }
    if (state.user.isTitan && nameApi === 'myopal') { config.headers['is-titan'] = true }
    await commitBookCurrent({ commit, val: value, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getBookById > response', response)
    console.log('getBookById -> state.bookCurrent.lastGetValue', state.bookCurrent.lastGetValue)
    //  Verify if it is the last clicked event to show it
    if (response && value === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getBookById: ${err.message || err}`)
    throw err
  }
}

async function actionGetBookCurrent ({ state, dispatch, commit, vm }, { value }) {
  console.log('actionGetBookCurrent: ', value)
  await commitBookCurrent({ commit, nameVal: 'isPending', val: true })
  await commitBookCurrent({ commit, nameVal: 'isError', val: false })
  try {
    const { userToken } = await vm.$getTokenAndSequalliaKey()

    let nameApi = 'opal'
    if (value === state.user.myBkId || state.user.isTitan) { nameApi = 'myopal' }

    const response = await getBookById({ state, commit, value, userToken, nameApi })
    console.log('actionGetBookCurrent -> response', response)

    //  Check sequallia key
    const isValid = isSequalliaKeyValid(response)
    console.log('isValid', isValid)
    handleIfNoCreditsView({ state, dispatch, commit, response, typeOfAction: 'view-books' })
    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })
    if (nameApi === 'opal') { await commitCreditUsed({ state, commit, nameCdtUsed: 'cdtUsedViewBk' }) }
    if (nameApi === 'myopal') { await commit('set_oneEffect', { nameVal: 'needFreshMyBkData', val: false }) }

    await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
    //  need set_countChangeBookOrRefCurrent after isPending false
    if (isValid && response && response.data) {
      await setNewBookCurrent(commit, response.data)
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.log('this.$store.state.bookCurrent', state.bookCurrent)
  } catch (err) {
    if (value === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      await commitBookCurrent({ commit, nameVal: 'isError', val: true })
      await commitBookCurrent({ commit, nameVal: 'bookDataCurrent', val: {} })
      await commitBookCurrent({ commit, val: '', nameVal: 'bookIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.error(err)
  }
}

export { actionGetBookCurrent }
