export const refForms = [
  { vc: 'zzvcfres', child: false, vi: 'zzvifres-p', title: 'L\'essentiel', icon: 'IconStopWatch', formName: 'essSht' },
  { vc: 'zzvcfres', child: true, vi: 'zzvifres-cu', title: 'Usages principaux *' },
  { vc: 'zzvcfres', child: true, vi: 'zzvifres-cc', title: 'Photo principale' },
  { vc: 'zzvcfrmd', child: false, vi: 'zzvifrmd-p', title: 'Détails Mission', icon: 'IconDescription', formName: 'misDts' },
  { vc: 'zzvcfrbd', child: false, vi: 'zzvifrbd-p', title: 'Description Bâtiment(s) / Extérieurs', icon: 'IconCity', formName: 'bdDesc' },
  { vc: 'zzvcfrbd', child: true, vi: 'zzvifrbd-cnp', title: 'Ajouter une capacité de personnes' },
  { vc: 'zzvcfrbd', child: true, vi: 'zzvifrbd-cnf', title: 'Ajouter un nombre de niveaux' },
  { vc: 'zzvcfrbd', child: true, vi: 'zzvifrbd-cs', title: 'Ajouter une zone et sa surface' },
  { vc: 'zzvcfrbd', child: true, vi: 'zzvifrbd-ch', title: 'Ajouter un type de hauteur' },
  { vc: 'zzvcfrbl', child: false, vi: 'zzvifrbl-p', title: 'Budget / Coûts travaux', icon: 'IconEuro', formName: 'budLots' },
  { vc: 'zzvcfrbl', child: true, vi: 'zzvifrbl-c', title: 'Ajouter un coût travaux' },
  { vc: 'zzvcfrcl', child: false, vi: 'zzvifrcl-p', title: 'Certifications / Labels', icon: 'IconMedal', formName: 'certLabs' },
  { vc: 'zzvcfrcl', child: true, vi: 'zzvifrcl-c', title: 'Ajouter une certification ou un label' },
  { vc: 'zzvcfrmp', child: false, vi: 'zzvifrmp-p', title: 'Partenaires', icon: 'IconTeam', formName: 'mParts' },
  { vc: 'zzvcfrmp', child: true, vi: 'zzvifrmp-c', title: 'Ajouter un partenaire' },
  { vc: 'zzvcfrfr', child: false, vi: 'zzvifrfr-p', title: 'Réglementations', icon: 'IconGavel', formName: 'famRegul' },
  { vc: 'zzvcfrfr', child: true, vi: 'zzvifrfr-cs', title: 'Ajouter une réglementation sécurité' },
  { vc: 'zzvcfrfr', child: true, vi: 'zzvifrfr-ct', title: 'Ajouter une réglementation thermique' },
  { vc: 'zzvcfrdf', child: false, vi: 'zzvifrdf-p', title: 'Clos couvert & Second oeuvre', icon: 'IconPaint', formName: 'dtsFin' },
  { vc: 'zzvcfrdf', child: true, vi: 'zzvifrdf-c', title: 'Ajouter un élément ou une prestation' },
  { vc: 'zzvcfrdt', child: false, vi: 'zzvifrdt-p', title: 'Technique', icon: 'IconCompass', formName: 'dtsTec' },
  { vc: 'zzvcfrdt', child: true, vi: 'zzvifrdt-c', title: 'Ajouter un élément ou une prestation' },
  { vc: 'zzvcfrst', child: false, vi: 'zzvifrst-p', title: 'Tags / Particularités', icon: 'IconSparkles', formName: 'sumTags' },
  { vc: 'zzvcfrst', child: true, vi: 'zzvifrst-c', title: 'Ajouter des mots clés' },
  { vc: 'zzvcfrnra', child: false, vi: 'zzvifrnra-p', title: 'Ils en parlent', icon: 'IconMegaphone', formName: 'refArtcs' },
  { vc: 'zzvcfrnra', child: true, vi: 'zzvifrnra-c', title: 'Ajouter un article / lien' },
  { vc: 'zzvcfrei', child: false, vi: 'zzvifrei-p', title: 'Photos supplémentaires', icon: 'IconCamera', formName: 'refImgs' }
]

export const socForms = [
  { vc: 'zzvcfsrs', child: false, vi: 'zzvifsrs-p', title: 'Résumé société', icon: 'IconStopWatch', formName: 'resumeSociety' },
  { vc: 'zzvcfsaags', child: false, vi: 'zzvifsaags-p', title: 'Nos agences', icon: 'IconGlobe', formName: 'accAgys' },
  // AccountyAgency has a special treatment : not totally a child because he has himself a child
  { vc: 'zzvcfsaags', child: true, vi: 'zzvifsaagy-p', title: 'Modifier cette agence' },
  { vc: 'zzvcfsaags', child: true, vi: 'zzvifsaagy-c', title: 'Ajouter un contact' },
  { vc: 'zzvcfsca', child: false, vi: 'zzvifsca-p', title: 'Nos Activités & Compétences', icon: 'IconHardHatFull', formName: 'craftsActs' },
  { vc: 'zzvcfsca', child: true, vi: 'zzvifsca-c', title: 'Ajouter une activité / compétence' },
  { vc: 'zzvcfsfu', child: false, vi: 'zzvifsfu-p', title: 'Nos Secteurs de prédilection', icon: 'IconBuildingFull', formName: 'favsUses' },
  { vc: 'zzvcfsfu', child: true, vi: 'zzvifsfu-c', title: 'Ajouter des usages / secteurs' },
  { vc: 'zzvcfsgd', child: false, vi: 'zzvifsgd-p', title: 'Qui sommes-nous ?', icon: 'IconDescription', formName: 'genDesc' },
  { vc: 'zzvcfswl', child: false, vi: 'zzvifswl-p', title: 'Les mots du Président', icon: 'IconComment', formName: 'wordsLd' },
  { vc: 'zzvcfswl', child: true, vi: 'zzvifswl-c', title: 'Ajouter une photo' },
  { vc: 'zzvcfsap', child: false, vi: 'zzvifsap-p', title: 'Nos partenaires phares', icon: 'IconTeam', formName: 'allParts' },
  { vc: 'zzvcfsap', child: true, vi: 'zzvifsap-c', title: 'Modifier nos partenaires phares' },
  { vc: 'zzvcfswq', child: false, vi: 'zzvifswq-p', title: 'Nos Qualifications', icon: 'IconCompass', formName: 'wkQuals' },
  { vc: 'zzvcfswq', child: true, vi: 'zzvifswq-c', title: 'Ajouter une qualification' },
  { vc: 'zzvcfsta', child: false, vi: 'zzvifsta-p', title: 'Nos Récompenses & Prix', icon: 'IconTrophyFull', formName: 'topAwds' },
  { vc: 'zzvcfsta', child: true, vi: 'zzvifsta-c', title: 'Ajouter une récompense' },
  { vc: 'zzvcfsnsa', child: false, vi: 'zzvifsnsa-p', title: 'Ils parlent de nous', icon: 'IconMegaphone', formName: 'socArtcs' },
  { vc: 'zzvcfsnsa', child: true, vi: 'zzvifsnsa-c', title: 'Ajouter un article / lien' },
  { vc: 'zzvcfsnm', child: false, vi: 'zzvifsnm-p', title: 'Nous sommes membre de', icon: 'IconNetwork', formName: 'netMbs' },
  { vc: 'zzvcfsnm', child: true, vi: 'zzvifsnm-c', title: 'Ajouter une adhésion / participation' },
  { vc: 'zzvcfsst', child: false, vi: 'zzvifsst-p', title: 'Nos Moyens & Outils', icon: 'IconTools', formName: 'spTools' },
  { vc: 'zzvcfsst', child: true, vi: 'zzvifsst-c', title: 'Ajouter un  moyen / outil' },
  { vc: 'zzvcfssl', child: false, vi: 'zzvifssl-p', title: 'Informations Légales', icon: 'IconInformation', formName: 'someLegal' },
  { vc: 'zzvcfssl', child: true, vi: 'zzvifssl-cl', title: 'Ajouter un dirigeant' },
  { vc: 'zzvcfscsl', child: false, vi: 'zzvifscsl-p', title: 'Logo société', icon: 'IconSquareUp', formName: 'socLogo' },
  { vc: 'zzvcfscsl', child: true, vi: 'zzvifscsl-c', title: 'Modifier logo société' }
]
