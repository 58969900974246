import { filterCapitalize } from '../utils/filters'

export default ({
  computed: {
    userHasSequalliaKey () { return this.$store.state.user.sequalliaKey },
    uFirstNm () { return this.$store.state.user.uFirstNm },
    alertHelloOnce () { return this.$store.state.effect.alertHelloOnce }
  },
  watch: {
    // whenever watch changes, this function will run
    userHasSequalliaKey: function () {
      if (this.userHasSequalliaKey && this.uFirstNm && !this.alertHelloOnce) {
        const msg = `Bonjour ${filterCapitalize(this.uFirstNm)}`
        this.$store.dispatch('set_oneEffect', { nameVal: 'alertHelloOnce', val: true })
        this.$store.dispatch('set_alertHeader', { msg, color: 'yellow' })
      }
    }
  }
})
