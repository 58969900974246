
function filterCapitalize (val) {
  if (!val) return ''
  val = val.toString()
  return val.charAt(0).toUpperCase() + val.slice(1)
}

function filterThousands (val) {
  if (!val) return ''
  if (typeof val === 'number') { val = val.toString() }
  val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return val
}

function filterUppercase (val) {
  if (!val) return ''
  return val.toUpperCase()
}

export {
  filterCapitalize,
  filterThousands,
  filterUppercase
}
