<template>
  <div :class="{'zzcmr-form-right-value-bigger': isFormRightValueBigger}">
    <div :class="{'s-input-number-bigger': isNumberBigger}">
      <div
        class="s-form-input"
        :class="[isInline ? 's-form-inline' : '']"
      >
        <label
          class="s-form-label"
          :for="idInput"
        >
          <div class="s-text-label">
            {{ labelHolder }}
          </div>
        </label>
        <div
          class="s-input-block"
          :class="{'s-input-text-red': isError}"
        >
          <input
            :id="idInput"
            ref="input"
            placeholder=""
            type="text"
            autocomplete="nope"
            class="s-input-classic"
            :class="{'s-input-number': isNumber}"
            v-bind="$attrs"
            @keypress.13.prevent
            v-on="listeners"
          >
          <label
            v-if="isFill"
            :class="[isLeftInput ? 's-deselect-label-thinner' : 's-deselect-label']"
            title="Supprimer"
            :for="idDeselect"
          >
            <button
              :id="idDeselect"
              ref="input-deselect"
              class="s-deselect-btn"
              tabindex="-1"
              @click.prevent="$emit('deselect')"
            >
              <BaseIconDeselect />
            </button>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  // Render wrappers transparents
  inheritAttrs: false,
  props: {
    idInput: {
      type: String,
      required: true
    },
    labelHolder: {
      type: String,
      required: true
    },
    isFill: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    // Align text right
    isNumber: {
      type: Boolean,
      default: false
    },
    isNumberBigger: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    },
    isFormRightValueBigger: {
      type: Boolean,
      default: false
    },
    isLeftInput: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    idDeselect () {
      return `${this.idInput}-deselect`
    },
    listeners () {
      return {
        ...this.$listeners,
        input: event =>
          this.$emit('input', event)
      }
    }
  }
}
</script>
