const { poseidonObj } = require('../utils/utils-poseidon')
const { asyncAxiosCommon } = require('./axios-common')
const { handleResStatus } = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

async function asyncUpdateStopMails ({ state, value, userId, bookId }) {
  try {
    console.log('asyncUpdateStopMails > state', state)
    //  Value is coming from user input
    const poseidonVal = poseidonObj(value)
    let id
    let url
    if (userId) {
      url = `${BASE_URL_API}/shield/users/`
      id = userId
    }
    if (bookId) {
      url = `${BASE_URL_API}/shield/books/`
      id = bookId
    }
    if (!url || !id) { throw new Error('need id and url') }
    const config = {
      method: 'PUT',
      timeout: 15000,
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { id },
      data: poseidonVal,
      url
    }
    const response = await asyncAxiosCommon(config)
    console.log('asyncUpdateStopMails > response', response)
    return response
  } catch (err) {
    err.message = `asyncUpdateStopMails: ${err.message}`
    throw err
  }
}

//  Set userId to stop user mails
//  Set bookId to stop book mails
async function actionStopMails ({ state, dispatch, commit }, { value, bookId, userId }) {
  const nameValParent = 'updatingStopMails'
  const actNm = 'set_nestedOne'
  try {
    console.log('actionStopMails > value: ', value)
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
    const response = await asyncUpdateStopMails({ state, value, bookId, userId })
    console.log('actionStopMails > response:', response)
    //  Alert if error
    handleResStatus({ dispatch, response, noSeqKey: true })
    const data = response.data
    const arrayResults = [
      { nameVal: 'resStopCold', val: data.stopCold },
      { nameVal: 'resStopConv', val: data.stopConv },
      { nameVal: 'resStopNews', val: data.stopNews },
      { nameVal: 'isPending', val: false }
    ]
    for (const obj of arrayResults) {
      await commit(actNm, { nameValParent, nameVal: obj.nameVal, val: obj.val })
    }
  } catch (err) {
    console.error(err)
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  }
}

export {
  actionStopMails
}
