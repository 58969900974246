<template>
  <div>
    <div class="s-form-input">
      <label
        class="s-form-label"
        :for="idArea"
      >
        <div class="s-text-label">
          {{ labelHolder }}
        </div>
      </label>
      <div :class="{'s-input-text-red': isError}">
        <div :class="[isHugeArea ? 's-text-area-block-huge' : 's-text-area-block']">
          <textarea
            :id="idArea"
            ref="input"
            placeholder=""
            rows="3"
            type="text"
            autocomplete="nope"
            class="s-text-area-classic"
            v-bind="$attrs"
            v-on="listeners"
          />
          <!-- <label
            class="s-deselect-label"
            title="Supprimer"
            :for="idDeselect"
            v-if="isFill"
            >
            <button
              class="s-deselect-btn"
              ref="text-area-deselect"
              tabindex="-1"
              :id="idDeselect"
              @click.prevent="$emit('deselect')"
              >
              <BaseIconDeselect/>
            </button>
          </label> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  // Render wrappers transparents
  inheritAttrs: false,
  props: {
    idInput: {
      type: String,
      required: true
    },
    labelHolder: {
      type: String,
      required: true
    },
    isFill: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isHugeArea: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    idArea () {
      return `${this.idInput}-text-area`
    },
    // idDeselect () {
    //   return `${this.idInput}-deselect`
    // },
    listeners () {
      return {
        ...this.$listeners,
        input: event =>
          this.$emit('input', event)
      }
    }
  },
  mounted () {
    console.debug('this.idInput: ', this.idInput)
    console.debug('this.idArea: ', this.idArea)
  }
}
</script>
