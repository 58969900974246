<template>
  <div>
    <div
      class="s-container"
      @click="closeEverywhere()"
    >
      <transition
        name="s-modal"
        appear
      >
        <div class="s-modal-mask">
          <div class="s-modal-wrapper-global">
            <div class="s-modal-container-center">
              <button
                class="s-close-out-modal"
                @click.prevent="$emit('close')"
              />
              <slot name="content" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import closeModalWithEsc from '../../mixins/close-modal-with-esc'
import htmlOverflowHidden from '../../mixins/html-overflow-hidden'

export default {
  name: 'BaseModal',
  mixins: [
    closeModalWithEsc,
    htmlOverflowHidden
  ],
  props: {
    isCloseEverywhere: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeEverywhere () {
      if (this.isCloseEverywhere) { this.$emit('close') }
    }
  }
}

</script>
