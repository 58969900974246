<template>
  <div>
    <div :class="[isRight ? 's-red-wrapper-right' : 's-red-wrapper-left']">
      <span :class="{'s-red-div-right-ext': isRightExt}">
        <div :class="[isRight ? 's-red-div-right' : heightRedDiv]">
          <span :class="{'s-red-yellow': isYellow}">
            <span
              class="s-red-span"
              :class="[isFill ? 's-red-out' : 's-red-in']"
              :title="title"
            />
          </span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isRight: {
      type: Boolean,
      default: false
    },
    isFill: {
      type: Boolean,
      default: false
    },
    isYellow: {
      type: Boolean,
      default: false
    },
    isRightExt: {
      type: Boolean,
      default: false
    },
    isBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    heightRedDiv () { return this.isBtn ? 's-red-div-left-btn' : 's-red-div-left' },
    title () { return this.isYellow ? 'Information non obligatoire mais recommandée' : 'Information requise' }
  }
}
</script>
