const { actionGetRefDirectly } = require('./onyx-get-ref')
const { asyncAxiosCommon } = require('./axios-common')
const {
  commitBookCurrent,
  setNewBookCurrent
} = require('./common-get-book')
const {
  handleResStatus
} = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_KEY_PRODUCT_FOR_UNKNOWN = process.env.VUE_APP_API_KEY_PRODUCT_FOR_UNKNOWN

async function getBookIdDirectly (bkPath) {
  try {
    const url = `${BASE_URL_API}/onyx/paths`
    console.debug('getBookIdDirectly -> url', url)
    const config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { path: bkPath },
      timeout: 15000,
      url
    }
    console.log('getBookIdDirectly > config', config)
    const response = await asyncAxiosCommon(config)
    console.log('getBookIdDirectly > response', response)
    return response
  } catch (err) {
    console.error(`getBookIdDirectly: ${err.message || err}`)
    throw err
  }
}

async function getBookDirectly ({ state, commit, bookId }) {
  try {
    const url = `${BASE_URL_API}/onyx/books`
    console.debug('getBookDirectly -> url', url)
    const config = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': API_KEY_PRODUCT_FOR_UNKNOWN
      },
      params: { id: bookId },
      timeout: 15000,
      url
    }
    console.log('getBookDirectly > config', config)
    await commitBookCurrent({ commit, val: bookId, nameVal: 'lastGetValue' })
    const response = await asyncAxiosCommon(config)
    console.log('getBookDirectly > response', response)
    //  Verify if it is the last clicked event to show it
    if (response && bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, val: null, nameVal: 'lastGetValue' })
      return response
    }
  } catch (err) {
    console.error(`getBookDirectly: ${err.message || err}`)
    throw err
  }
}

async function actionGetBookDirectly ({ state, dispatch, commit }, { bkPath, refId }) {
  console.log('actionGetBookDirectly > bkPath:', bkPath)
  await commitBookCurrent({ commit, nameVal: 'isPending', val: true })
  await commitBookCurrent({ commit, nameVal: 'isError', val: false })
  let bookId
  try {
    const responseId = await getBookIdDirectly(bkPath)
    console.log('actionGetBookDirectly -> responseId', responseId)
    bookId = responseId && responseId.data && responseId.data.bookId

    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response: responseId })

    if (!bookId) {
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      await commitBookCurrent({ commit, nameVal: 'isError', val: true })
      await commitBookCurrent({ commit, nameVal: 'bookDataCurrent', val: {} })
      await commitBookCurrent({ commit, val: '', nameVal: 'bookIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
      return
    }

    const response = await getBookDirectly({ state, commit, bookId })
    console.log('actionGetBookDirectly -> response', response)

    handleResStatus({ dispatch, nameAction: 'set_nestedOne', nameValParent: 'responseView', commit, response })

    if (response && response.data) {
      //  Add bkPath in front instead of actionGetBookCurrent for security
      response.data.bkPath = bkPath
      await setNewBookCurrent(commit, response.data)
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      //  need set_countChangeBookOrRefCurrent after isPending false
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
      if (bookId && refId) {
        await actionGetRefDirectly({ state, dispatch, commit }, { refId, bookId })
      }
    }
    await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
    console.log('this.$store.state.bookCurrent', state.bookCurrent)
  } catch (err) {
    if (bookId && bookId === state.bookCurrent.lastGetValue) {
      await commitBookCurrent({ commit, nameVal: 'isPending', val: false })
      await commitBookCurrent({ commit, nameVal: 'isError', val: true })
      await commitBookCurrent({ commit, nameVal: 'bookDataCurrent', val: {} })
      await commitBookCurrent({ commit, val: '', nameVal: 'bookIdCurrent' })
      await commit('set_countChangeBookOrRefCurrent', { nameValParent: 'bookCurrent', nameVal: 'countChangeBookCurrent' })
    }
    console.error(err)
  }
}

export { actionGetBookDirectly }
