// export const randomId = () => {
//   const random = Math.random().toString(16).slice(2)
//   return random
// }

// export const deepFreeze = (obj) => {
//   const propNames = Object.getOwnPropertyNames(obj)
//   for (const name of propNames) {
//     const value = obj[name]
//     obj[name] = value && typeof value === 'object' ? deepFreeze(value) : value
//   }
//   return Object.freeze(obj)
// }

//  Not work for object nested inside object or array
//  Work only for string and array inside an object, and nested at first level:
function checkIfFormObjHasValues (formObj) {
  const keys = Object.keys(formObj)
  for (const key of keys) {
    if (formObj[key] && !Array.isArray(formObj[key])) { return true }
    if (formObj[key] && Array.isArray(formObj[key]) && formObj[key].length) { return true }
  }
}

function dateNowSecondes () {
  const millis = Date.now()
  const dateSec = Math.floor(millis / 1000)
  return dateSec
}

function getRandomIntInclusive (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function lowerAndNormalizeString (string) {
  if (!string) { return '' }
  const lowerCase = string.toLowerCase()
  const normalizeStr = suppressAccents(lowerCase)
  return normalizeStr
}

function newDateFormatFile () {
  const dateObj = new Date()
  let year = dateObj.getUTCFullYear()
  year = year.toString().slice(-2)
  let month = dateObj.getUTCMonth() + 1 // months from 1-12
  if (month < 10) { month = '0' + month }
  let day = dateObj.getDate()
  if (day < 10) { day = '0' + day }
  return year + month + day
}

function normalizeStringToNameFile (string) {
  string = lowerAndNormalizeString(string)
  string = string.replace(/'/g, '-')
  string = string.replace(/\//g, '-')
  // suppressSpecialCharactersExceptedTheseOnes
  string = string.replace(/[^_–-\w\s]/gi, '')
  string = string.replace(/ /g, '-')
  string = string.replace(/--/g, '-')
  string = string.replace(/--/g, '-')
  return string
}

function shuffleArray (array) {
  console.time('shuffleTime')
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  console.timeEnd('shuffleTime')
  return array
}

// sleep time expects milliseconds
function sleepMs (timeMs) {
  return new Promise((resolve) => setTimeout(resolve, timeMs))
}

function suppressAccents (str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function outerHTML (node) {
  const string = node.outerHTML || new XMLSerializer().serializeToString(node)
  return string
}

export {
  checkIfFormObjHasValues,
  dateNowSecondes,
  getRandomIntInclusive,
  lowerAndNormalizeString,
  newDateFormatFile,
  normalizeStringToNameFile,
  outerHTML,
  shuffleArray,
  sleepMs,
  suppressAccents
}
