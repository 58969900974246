export default ({
  created () {
    document.addEventListener('keydown', this.closeModalWithEsc)
  },
  destroyed () {
    document.removeEventListener('keydown', this.closeModalWithEsc)
  },
  methods: {
    closeModalWithEsc (e) {
      const keyEsc = 27
      console.assert(keyEsc === 27, 'keyEsc wrong')
      if (e.keyCode === keyEsc) { this.$emit('close') }
    }
  }
})
