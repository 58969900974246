<template>
  <div>
    <input
      :id="id"
      ref="checkbox"
      :class="classInput"
      type="checkbox"
      :value="value"
      :checked="checkedValue"
      @click="selectClickMethod()"
    >
    <label
      :for="id"
      class="s-label-checkbox"
    >
      <span :class="isBigCheckbox ? 's-span-checkbox-big' : 's-span-checkbox'">
        <BaseIconCheckbox />
      </span>
      <span class="s-label-text-checkbox">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  //  checked can be an array inside model
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    classInput: {
      type: String,
      default: 's-input-checkbox'
    },
    value: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    checked: [
      Boolean, Array
    ],
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    isBigCheckbox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkedValue () {
      return Array.isArray(this.checked) ? this.checked.includes(this.value) : this.checked
    }
  },
  methods: {
    selectClickMethod () {
      if (Array.isArray(this.checked)) { this.clickArray() }
      if (typeof this.checked === 'boolean') { this.clickBoolean() }
      this.$emit('click')
    },
    clickArray () {
      console.log('this.checked before click', this.checked)
      let checked = Array.from(this.checked)
      if (checked.includes(this.value)) {
        checked.splice(checked.indexOf(this.value), 1)
      } else {
        checked.push(this.value)
      }
      console.debug('this.checked after click', checked)
      this.$emit('change', checked)
    },
    clickBoolean () {
      console.log('this.checked before click', this.checked)
      let checked = !this.checked
      console.debug('this.checked after click', checked)
      this.$emit('change', checked)
    }
  }
}
</script>
