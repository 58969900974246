//  Return always true if isValidKey is not === false
function isSequalliaKeyValid (response) {
  if (response && response.data && response.data.isValidKey === false) { return false }
  //  All responses have not validKey, true by default
  return true
}

// typeOfAction : search or view
function commitsResponseNoCredits ({ state, dispatch, commit, data, typeOfAction, isCrédits }) {
  if (state.user.isTitan) { return }
  const isNoCrédits = !isCrédits || (data.message && data.message.includes('no credits'))
  if (isNoCrédits) {
    let frWord
    if (typeOfAction === 'search') { frWord = 'recherches' }
    if (typeOfAction === 'view-books') { frWord = 'fiches sociétés vues' }
    if (typeOfAction === 'view-refs') { frWord = 'fiches références vues' }
    const endMessage = 'a été atteinte. Vos crédits sont rechargés chaque Jeudi à minuit. Consultez nos offres premiums Sequallia Search pour en savoir plus & obtenir davantage de crédits. '
    const msgErrorS401 = `Votre limite hebdomadaire de ${frWord} ${endMessage}`
    dispatch('set_alertHeader', { msg: `Limite hebdomadaire de ${frWord} atteinte`, color: 'red' }, { root: true })
    commit('set_nestedOne', { nameValParent: 'responseSearch', nameVal: 'msgErrorS401', val: msgErrorS401 })
    return
  }
  //  Key is invalid but not because no credits
  const msgErrorS401 = 'Erreur inattendue. Vous n\'êtes pas autorisé à accéder à cette ressource. Veuillez contacter le support.'
  dispatch('set_alertHeader', { msg: 'Accès non autorisé', color: 'red' }, { root: true })
  commit('set_nestedOne', { nameValParent: 'responseSearch', nameVal: 'msgErrorS401', val: msgErrorS401 })
  commit('set_nestedOne', { nameValParent: 'responseView', nameVal: 'msgErrorS401', val: msgErrorS401 })
}

function handleIfNoCreditsSearch ({ state, dispatch, commit, response }) {
  const isCrédits = state.user.cdtSh && state.user.cdtSh > 0
  const isValid = isSequalliaKeyValid(response) && isCrédits
  if (isValid) { return }
  const data = response.data
  commitsResponseNoCredits({ state, dispatch, commit, data, typeOfAction: 'search', isCrédits })
  commit('set_nestedOne', { nameValParent: 'responseSearch', nameVal: 'resStatus', val: 'S401' })
}

function handleIfNoCreditsView ({ state, dispatch, commit, response, typeOfAction }) {
  let isCrédits
  if (typeOfAction === 'view-books') { isCrédits = state.user.cdtVwBk && state.user.cdtVwBk > 0 }
  if (typeOfAction === 'view-refs') { isCrédits = state.user.cdtVwRf && state.user.cdtVwRf > 0 }
  const isValid = isSequalliaKeyValid(response) && isCrédits
  if (isValid) { return }
  const data = response.data
  commitsResponseNoCredits({ state, dispatch, commit, data, typeOfAction, isCrédits })
  commit('set_nestedOne', { nameValParent: 'responseView', nameVal: 'resStatus', val: 'S401' })
}

//  Need response with status inside:
function handleResStatus ({
  dispatch,
  response,
  noSeqKey,
  //  msgWrongKey optionnal
  msgWrongKey,
  //  commit, nameAction & nameValParent are optionnal (not used by postCommonImage for example)
  commit,
  nameAction,
  nameValParent
}) {
  const isValid = noSeqKey || isSequalliaKeyValid(response)
  console.log('handleResStatus > response:', response)
  console.log('handleResStatus > isValid:', isValid)
  const status = response && response.status
  if (status && (nameValParent && commit && nameAction)) {
    commit(nameAction, { nameValParent, nameVal: 'resStatus', val: response.status || 500 })
  }
  if (status === 200 || status === 201 || status === 204) { return }
  let msg = 'Erreur inattendue'
  let color = 'dark'
  if (status === 403 || status === 429) {
    msg = 'Votre accès est temporairement limité'
    color = 'yellow'
  }
  if (!isValid) {
    msg = msgWrongKey || 'Accès refusé'
    color = 'dark'
  }
  dispatch('set_alertHeader', { msg, color }, { root: true })
}

export {
  handleIfNoCreditsSearch,
  handleIfNoCreditsView,
  handleResStatus,
  isSequalliaKeyValid
}
