import { refForms, socForms } from '../utils/store-view-forms'
import { keysMutatedSequalliaKey } from '../utils/store-const'
import { sleepMs } from '../utils/utils-various'
import { actionOpenGetRefAd } from '../services/open-get-ref-ad'
import { actionOpenGetBookAd } from '../services/open-get-book-ad'
import { actionGetBookDirectly } from '../services/onyx-get-book'
import { actionGetBookTemp } from '../services/emerald-get-book-temp'
import { actionGetRefDirectly } from '../services/onyx-get-ref'
import { actionOpenGetSearchBooksAd } from '../services/open-get-search-books-ad'
import { actionOpenGetSearchRefsAd } from '../services/open-get-search-refs-ad'
import {
  actionBooksDirectSearch,
  actionBooksComplexSearch
} from '../services/jade-get-books'
import {
  actionRefsDirectSearch,
  actionRefsComplexSearch
} from '../services/jade-get-refs'
import { actionGetBookCurrent } from '../services/opal-get-book'
import { actionGetRefCurrent } from '../services/opal-get-ref'
import { actionOpenCheckout } from '../services/rock-post-checkout'
import { actionGetBillInfos } from '../services/rock-get-books'
import { actionGetPortal } from '../services/rock-get-portal'
import { actionStopMails } from '../services/shield-put-stop-mails'
import { actionGetSequalliaKey } from '../services/iron-get-sequallia-key'
import { actionLinkUserWithBook } from '../services/amber-put-link-user-with-book'
import {
  actionGetItemsArrayFromLocalStorage,
  actionGetItemsBooleanFromLocalStorage
} from '../utils/local-storage'

export default ({
  // Attention, Suppress strict mode in production
  strict: process.env.NODE_ENV !== 'production',
  state: {
    BASE_URL_API: process.env.VUE_APP_BASE_URL_API,
    user: {
      sequalliaKey: '',
      userToken: '',
      hasToken: false,
      failedToken: false,
      // Get from token :
      uTkId: '',
      // Get from DB :
      userId: '',
      uFirstNm: '',
      uLastNm: '',
      uTitle: '',
      uRole: '',
      isTitan: false,
      premNm: '',
      conNm: '',
      cdtVwRf: 0,
      cdtVwBk: 0,
      cdtSh: 0,
      myTmNm: '',
      hasNoBk: true,
      myBkId: '',
      myBkNm: '',
      myBkTown: '',
      myBkZipC: '',
      myBkLand: '',
      mySocSiren: '',
      stripeCusId: ''
    },
    // Get from my book :
    billInfos: {
      //  Fill infos book  & subscriptions bellow with request response only if empty values
      //  Keep infos modified by user
      bkNm: '',
      bkAdr: '',
      bkTown: '',
      bkZipC: '',
      bkLand: '',
      socSiren: ''
    },
    //  Update payConnect & paySearch infos in front after initial load - not update by request if already filled
    payConnect: {
      subNm: '',
      userId: '',
      billMail: ''
    },
    paySearch: {
      subNm: '',
      userId: '',
      billMail: ''
    },
    userSettings: {
      isSearchTypeSoc: true,
      isMyBookViewTypeEdit: true
    },
    sessionMetrics: {
      cdtUsedSearchRf: 0,
      cdtUsedSearchBk: 0,
      cdtUsedViewRf: 0,
      cdtUsedViewBk: 0,
      // nbSearch is to update btn switch key during search by partner & to check jadopen
      nbSearchRef: 0,
      nbSearchSoc: 0
    },
    alertHeader: {
      showAlertHeader: false,
      msgAlertHeader: '',
      colorAlertHeader: ''
    },
    // If false, last search is then a complex search
    isLastSearchWithDirectSearch: {
      lastSearchRef: false,
      lastSearchSoc: false
    },
    //  Services
    creatingUser: {
      isPending: false,
      isError: false,
      isUserRecentlyCreated: false
    },
    gettingBillInfos: {
      isPending: false,
      isError: false
    },
    creatingCheckout: {
      isPending: false,
      isError: false,
      msgErrorCheckout: ''
    },
    gettingPortal: {
      isPending: false,
      isError: false,
      noPortalUrl: false
    },
    updatingUsersBook: {
      isPending: false,
      isError: false,
      isUserRecentlyLinked: false
    },
    updatingStopMails: {
      isPending: false,
      isError: false,
      resStopCold: false,
      resStopConv: false,
      resStopNews: false
    },
    gettingSequalliaKey: {
      seqKeyCurrent: 0,
      isPending: false,
      isError: false
    },
    responseSearch: {
      resStatus: 0,
      msgErrorS401: ''
    },
    responseView: {
      resStatus: 0,
      msgErrorS401: ''
    },
    directSearchSoc: {
      lastJade: '',
      currentJade: '',
      isPending: false,
      isError: false,
      lastGetValue: null
    },
    directSearchRef: {
      lastJade: '',
      currentJade: '',
      isPending: false,
      isError: false,
      lastGetValue: null
    },
    complexSearchSoc: {
      lastJade: '',
      currentJade: '',
      isPending: false,
      isError: false,
      lastGetValue: null
    },
    complexSearchRef: {
      lastJade: '',
      currentJade: '',
      isPending: false,
      isError: false,
      lastGetValue: null
    },
    searchResults: {
      socResults: [],
      refResults: []
    },
    bookCurrent: {
      bookIdCurrent: '',
      bkNmCurrent: '',
      bkTownCurrent: '',
      bkZipCCurrent: '',
      bkLandCurrent: '',
      isPending: false,
      isError: false,
      bookDataCurrent: {},
      countChangeBookCurrent: 0,
      lastGetValue: null
    },
    refCurrent: {
      refIdCurrent: '',
      refNmCurrent: '',
      // socAuthorName = bkNm
      socAuthorName: '',
      isNowR: false,
      endYr: 2020,
      isPending: false,
      isError: false,
      // fileAdId & bkPath for ads are inside refDataCurrent
      refDataCurrent: {},
      countChangeRefCurrent: 0,
      lastGetValue: null
    },
    refCopy: {
      refNmCopy: '',
      refOrigId: '',
      refDataCopyOrigin: {},
      refDataCopyImport: {}
    },
    selectionList: {
      isFullReferences: false,
      isFullBooks: false,
      listReferences: [],
      listBooks: []
    },
    genHistory: {
      lastJadopen: []
    },
    view: {
      viewComp: 'zzvvs',
      viewInside: '',
      refForms,
      socForms
    },
    //  Effects & metrics
    effect: {
      userDelAuthor: '',
      userForbiddenReason: '',
      showModalCanNotUseCredits: false,
      showModalLinkUserNeed: false,
      showModalLinkUserForbidden: false,
      showModalNewUser: false,
      showModalNewUserOnce: false,
      showModalNoCredits: false,
      showModalUserDeleted: false,
      showModalWaitingPortal: false,
      showModalWaitingSequalliaKey: false,
      lastNameCreditLimited: '',
      homePageVisitedOnce: false,
      mySelectionVisitedOnce: false,
      isMapBounce: true,
      isBuildingBounce: true,
      isTeamBounce: false,
      isTeamBounceTrueOnce: false,
      showHeaderModalForm: true,
      // needFreshMyBkData is true after each modification on my book
      needFreshMyBkData: false,
      //  Count google maps triggered
      countMaps: 0,
      counterComingSoonClicked: 0,
      alertHelloOnce: false,
      titlePrices: ''
    },
    //  For navigate inside comp
    undoRedoSave: {
      viewSaved: '',
      undoArray: [],
      redoArray: [],
      resetPossible: true
    },
    photoBlank: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    //  A little yellow
    photoError: 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8eVbMHgAGwAIdzxF+rAAAAABJRU5ErkJggg==',
    currentYear: new Date().getFullYear()
  },
  actions: {
    async set_viewComp ({ commit }, valObj) {
      await commit(valObj.nameAction, valObj)
    },
    async set_viewInside ({ commit }, valObj) {
      await commit(valObj.nameAction, valObj)
    },
    async set_alertHeader ({ commit }, valObj) {
      if (valObj.isShow === false) {
        await commit('set_alertHeader', valObj)
      } else {
        valObj.isShow = true
        console.log('set_alertHeader -> valObj', JSON.stringify(valObj))
        await commit('set_alertHeader', valObj)
        //  Wait 4 secondes
        await sleepMs(valObj.timeMs || 4000)
        valObj.isShow = false
      }
      await commit('set_alertHeader', valObj)
    },
    async save_undoRedo ({ commit }, valObj) {
      await commit(valObj.nameAction, valObj)
    },
    async unset_effectMapBounce ({ commit }, valObj) {
      await commit(valObj.nameAction, valObj)
    },
    async set_oneEffect ({ commit }, valObj) {
      await commit('set_oneEffect', valObj)
    },
    async set_nestedOne ({ commit }, valObj) {
      console.log('set_nestedOne -> valObj', valObj)
      await commit('set_nestedOne', valObj)
    },
    async set_userSettings ({ commit }, valObj) {
      await commit('set_userSettings', valObj)
    },
    async set_userData ({ commit }, valObj) {
      await commit('set_userData', valObj)
    },
    async set_userFromToken ({ commit }, valObj) {
      await commit('set_userFromToken', valObj)
    },
    async set_userFromSequalliaKey ({ commit }, valObj) {
      await commit('set_userFromSequalliaKey', valObj)
    },
    async actionBooksDirectSearch ({ state, dispatch, commit }, valObj) {
      await actionBooksDirectSearch({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionRefsDirectSearch ({ state, dispatch, commit }, valObj) {
      await actionRefsDirectSearch({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionBooksComplexSearch ({ state, dispatch, commit }, valObj) {
      await actionBooksComplexSearch({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionRefsComplexSearch ({ state, dispatch, commit }, valObj) {
      await actionRefsComplexSearch({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionGetBookCurrent ({ state, dispatch, commit }, valObj) {
      await actionGetBookCurrent({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionGetRefCurrent ({ state, dispatch, commit }, valObj) {
      await actionGetRefCurrent({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionOpenGetRefAd ({ state, dispatch, commit }, valObj) {
      await actionOpenGetRefAd({ state, dispatch, commit }, valObj)
    },
    async actionOpenGetBookAd ({ state, dispatch, commit }, valObj) {
      await actionOpenGetBookAd({ state, dispatch, commit }, valObj)
    },
    async actionOpenGetSearchRefsAd ({ state, dispatch, commit }, valObj) {
      await actionOpenGetSearchRefsAd({ state, dispatch, commit }, valObj)
    },
    async actionOpenGetSearchBooksAd ({ state, dispatch, commit }, valObj) {
      await actionOpenGetSearchBooksAd({ state, dispatch, commit }, valObj)
    },
    async actionGetBookDirectly ({ state, dispatch, commit }, valObj) {
      await actionGetBookDirectly({ state, dispatch, commit }, valObj)
    },
    async actionGetBookTemp ({ state, dispatch, commit }, valObj) {
      await actionGetBookTemp({ state, dispatch, commit }, valObj)
    },
    async actionGetRefDirectly ({ state, dispatch, commit }, valObj) {
      await actionGetRefDirectly({ state, dispatch, commit }, valObj)
    },
    async actionGetSequalliaKey ({ state, commit }) {
      await actionGetSequalliaKey({ state, commit, vm: this._vm })
    },
    async actionOpenCheckout ({ state, dispatch, commit }, valObj) {
      await actionOpenCheckout({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionGetBillInfos ({ state, dispatch, commit }) {
      await actionGetBillInfos({ state, dispatch, commit, vm: this._vm })
    },
    async actionGetPortal ({ state, dispatch, commit }) {
      await actionGetPortal({ state, dispatch, commit, vm: this._vm })
    },
    async actionLinkUserWithBook ({ state, dispatch, commit }, valObj) {
      await actionLinkUserWithBook({ state, dispatch, commit, vm: this._vm }, valObj)
    },
    async actionStopMails ({ state, dispatch, commit }, valObj) {
      await actionStopMails({ state, dispatch, commit }, valObj)
    },
    async actionGetItemsArrayFromLocalStorage ({ commit }, valObj) {
      await actionGetItemsArrayFromLocalStorage({ commit }, valObj)
    },
    async actionGetItemsBooleanFromLocalStorage ({ commit }, valObj) {
      await actionGetItemsBooleanFromLocalStorage({ commit }, valObj)
    }
  },
  mutations: {
    set_nestedOne (state, { nameValParent, nameVal, val }) {
      console.debug('set_nestedOne -> nameVal:', nameVal)
      console.debug('set_nestedOne -> nameValParent:', nameValParent)
      state[nameValParent][nameVal] = val
      console.log(`set_nestedOne > ${nameValParent} > ${nameVal} > mutation: `, val)
    },
    set_viewComp (state, { nameAction, val }) {
      state.view.viewComp = val
      console.log(`${nameAction} > mutation: `, val)
    },
    set_viewInside (state, { nameAction, val }) {
      state.view.viewInside = val
      console.log(`${nameAction} > mutation: `, val)
    },
    set_alertHeader (state, { msg, color, isShow }) {
      const st = state.alertHeader
      st.showAlertHeader = isShow
      if (msg) { st.msgAlertHeader = msg }
      if (color) { st.colorAlertHeader = color }
      console.log('set_alertHeader > mutation: ', { msg, color, isShow })
    },
    save_undoRedo (state, valObj) {
      const st = state.undoRedoSave
      st.undoArray = valObj.undoArray
      st.redoArray = valObj.redoArray
      st.resetPossible = valObj.resetPossible
      st.viewSaved = valObj.viewSaved
      console.log(`${valObj.nameAction} > mutation: `, valObj)
    },
    unset_effectMapBounce (state, valObj) {
      state.effect.isMapBounce = valObj.isMapBounce
      state.effect.isTeamBounce = valObj.isTeamBounce
      state.effect.isTeamBounceTrueOnce = valObj.isTeamBounceTrueOnce
    },
    set_oneEffect (state, { nameVal, val }) {
      state.effect[nameVal] = val
      console.log(`set_oneEffect -> ${nameVal}`, val)
    },
    set_countChangeBookOrRefCurrent (state, { nameValParent, nameVal }) {
      state[nameValParent][nameVal] += 1
      console.log(`${nameValParent} > ${nameVal} > mutation: `, state[nameValParent][nameVal])
    },
    set_userSettings (state, { nameVal, val }) {
      state.userSettings[nameVal] = val
      console.log(`set_userSettings > ${nameVal} > mutation: `, val)
    },
    set_userData (state, { nameVal, val }) {
      state.user[nameVal] = val
      console.log(`set_userData > ${nameVal} > mutation: `, val)
    },
    set_userFromToken (state, userObj) {
      state.user.uTkId = userObj.oid
      state.user.uFirstNm = userObj.given_name
      state.user.uLastNm = userObj.family_name
      state.user.uTel = userObj.extension_Telephone
      state.user.uMail = userObj.emails[0]
      if (userObj.jobTitle) { state.user.uTitle = userObj.jobTitle }
      if (userObj.extension_Entreprise) { state.user.myTmNm = userObj.extension_Entreprise }
      console.log('set_userFromToken > mutation: ', userObj)
    },
    set_userFromSequalliaKey (state, { data }) {
      // set key infos only if sequalliaKey is different than previous one (needed for credits calculation)
      const currentKey = state.user.sequalliaKey
      const newKey = data.sequalliaKey
      if (currentKey !== newKey) {
        for (const key of keysMutatedSequalliaKey) { state.user[key] = data[key] }
        console.log('set_userFromSequalliaKey > mutation: ', data)
        console.log('set_userFromSequalliaKey > mutation > sequallia.key: ', data.sequalliaKey)
        return
      }
      console.log('set_userFromSequalliaKey > no change, same key')
    }
  },

  // GETTERS
  getters: {
    refFormParentByName: state => (formName) => {
      return state.view.refForms.find(form => form.formName === formName && !form.child)
    },
    socFormParentByName: state => (formName) => {
      return state.view.socForms.find(form => form.formName === formName && !form.child)
    },
    refFormByViewInside: state => (vi) => {
      return state.view.refForms.find(form => form.vi === vi)
    },
    socFormByViewInside: state => (vi) => {
      return state.view.socForms.find(form => form.vi === vi)
    }
  }
})
