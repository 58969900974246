const { asyncAxiosCommon } = require('./axios-common')
const { sleepMs } = require('../utils/utils-various')
const { handleResStatus } = require('./utils-handle-errors')

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API
const API_SUBSCRIPTION_KEY = process.env.VUE_APP_API_KEY_PRODUCT_FOR_MEMBERS

function checkIfAllowToAddNewUser ({ commit, data }) {
  let reason
  if (data.uNewNone) { reason = 'Cette société n\'accepte pas de nouveaux utilisateurs.' }
  if (data.usersLimited) { reason = 'Cette société a atteint son nombre limite d\'utilisateurs.' }
  if (reason) {
    commit('set_oneEffect', { nameVal: 'userForbiddenReason', val: reason })
    commit('set_oneEffect', { nameVal: 'showModalLinkUserForbidden', val: true })
  }
}

async function asyncLinkUserWithBook ({ state, value, userToken }) {
  try {
    //  No need body
    const url = `${BASE_URL_API}/amber/books/`
    const seqKeyCurrent = state.gettingSequalliaKey.seqKeyCurrent
    const config = {
      method: 'PUT',
      timeout: 15000,
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
        'sequallia-key': state.user.sequalliaKey,
        'user-id': state.user.userId,
        'user-token-id': state.user.uTkId,
        'seq-key-current': seqKeyCurrent.toString()
      },
      params: { id: value },
      url
    }
    console.debug('asyncLinkUserWithBook > config', config)
    const response = await asyncAxiosCommon(config)
    return response
  } catch (err) {
    console.error(`asyncLinkUserWithBook: ${err.message || err}`)
    throw err
  }
}

async function actionLinkUserWithBook ({ state, dispatch, commit, vm }, { value }) {
  console.log('actionLinkUserWithBook > value: ', value)
  const nameValParent = 'updatingUsersBook'
  const actNm = 'set_nestedOne'
  await commit(actNm, { nameValParent, nameVal: 'isPending', val: true })
  await commit(actNm, { nameValParent, nameVal: 'isError', val: false })
  try {
    const { userToken } = await vm.$getTokenAndSequalliaKey()
    const response = await asyncLinkUserWithBook({ state, commit, value, userToken })
    console.debug('actionLinkUserWithBook > response:', response)

    handleResStatus({
      dispatch,
      nameAction: actNm,
      nameValParent,
      commit,
      response,
      msgWrongKey: 'Connexion à cette société non autorisée'
    })
    const data = response && response.data
    if (data) {
      checkIfAllowToAddNewUser({ commit, data })
      if (data.userId) {
        const isNewSeqKey = true
        await sleepMs(300)
        await vm.$getTokenAndSequalliaKey(isNewSeqKey)
        //  Set needFreshMyBkData to pass idClicked === myBkId inside checkBookClickedBeforeGetBookCurrent
        await commit('set_oneEffect', { nameVal: 'needFreshMyBkData', val: true })
        await commit(actNm, { nameValParent, nameVal: 'isUserRecentlyLinked', val: true })
      }
    }
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
  } catch (err) {
    await commit(actNm, { nameValParent, nameVal: 'isPending', val: false })
    await commit(actNm, { nameValParent, nameVal: 'isError', val: true })
    console.error(err)
  }
}

export { actionLinkUserWithBook }
