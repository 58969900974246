import { actionGetSequalliaKey } from '../services/iron-get-sequallia-key'

export const MyPluginAuthSequalliaKey = {
  install: function (Vue, { store }) {
    Vue.prototype.$getTokenAndSequalliaKey = async function (isNewSeqKey) {
      try {
        const tokenAndSeqKey = await actionGetSequalliaKey({
          state: store.state,
          commit: store.commit,
          vm: this,
          isNewSeqKey
        })
        return tokenAndSeqKey
      } catch (err) {
        console.error('$getTokenAndSequalliaKey:', err)
        throw err
      }
    }
  }
}
