<template>
  <div>
    <div class="s-error-form">
      Veuillez {{ verb }} {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    val: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'max'
    },
    persText: {
      type: String,
      default: ''
    },
    nameElement: {
      type: String,
      default: 'éléments'
    }
  },
  data () {
    return {
      text: '',
      verb: ''
    }
  },
  created () {
    this.setTextError()
    this.setVerbError()
  },
  methods: {
    setTextError () {
      if (this.type === 'array') {
        this.text = `au maximum ${this.val} ${this.nameElement}`
      }
      if (this.type === 'max') {
        this.text = `moins de ${this.val} caractères`
      }
      if (this.type === 'numeric') {
        this.text = 'un nombre entier'
      }
      if (this.type === 'phoneNumber') {
        this.text = 'un numéro contenant au moins 10 chiffres'
      }
      if (this.type === 'minValue') {
        this.text = `un nombre entier supérieur ou égal à ${this.val}`
      }
      if (this.type === 'unit') {
        this.text = 'une unité'
      }
      if (this.type === 'numExact') {
        this.text = `un numéro à ${this.val} chiffres`
      }
      if (this.type === 'email') {
        this.text = 'un email, son format n\'est pas valide'
      }
      if (this.type === 'url') {
        this.text = 'une adresse url http:// ou https://, son format n\'est pas valide'
      }
      if (this.type === 'persText') {
        this.text = this.persText
      }
    },
    setVerbError () {
      this.verb = 'saisir'
      if (this.type === 'array') { this.verb = 'sélectionner' }
    }
  }
}
</script>
