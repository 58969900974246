import * as msal from '@azure/msal-browser'

const tenantName = 'sequallia'
const policySignInAndUp = 'b2c_1_sequallia-601-signup-signin'
const policyPassword = 'b2c_1_sequallia-601-password-reset'
const allPolicies = [policySignInAndUp, policyPassword]

const frontApiAud = 'd67d5582-57a8-4b28-a3f5-206087128dd1'

const BASE_URL_SPA = process.env.VUE_APP_BASE_URL_SPA

const msalConfig = {
  auth: {
    clientId: frontApiAud,
    authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policySignInAndUp}`,
    knownAuthorities: [`${tenantName}.b2clogin.com`],
    navigateToLoginRequestUrl: false
    // validateAuthority: false
    // postLogoutRedirectUri: BASE_URL_SPA
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {	
          return
        }
        switch (level) {	
          case msal.LogLevel.Error:	
            console.error(message)
            return
          case msal.LogLevel.Info:	
            console.info(message)
            return
          case msal.LogLevel.Verbose:	
            console.debug(message)
            return
          case msal.LogLevel.Warning:	
            console.warn(message)
        }
      }
    }
  }
}

const msalConfigPassword = JSON.parse(JSON.stringify(msalConfig))
msalConfigPassword.auth.authority = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyPassword}`

// Create the main myMSALObj instance
const myMSALObj = new msal.PublicClientApplication(msalConfig)
const myMSALObjPassword = new msal.PublicClientApplication(msalConfigPassword)

// Add here scopes for id token to be used at MS Identity Platform endpoints
const loginRequest = {
  scopes: [
    `https://sequallia.onmicrosoft.com/sequallia-back-end/access_back`
  ],
  redirectUri: BASE_URL_SPA
}

// Add here scopes for access token to be used at MS Graph API endpoints
const tokenRequest = {
  scopes: [
    `https://sequallia.onmicrosoft.com/sequallia-back-end/access_back`
  ],
  forceRefresh: false, // Set this to 'true' to skip a cached token and go to the server to get a new token
  redirectUri: BASE_URL_SPA
}

const silentRequest = {
  scopes: [
    `https://sequallia.onmicrosoft.com/sequallia-back-end/access_back`
  ]
}

function checkTokenIss (tokenDecoded) {
  if (tokenDecoded && tokenDecoded.iss) {
    for (const policy of allPolicies) {
      if (tokenDecoded.iss.includes(policy)) {
        return true
      }
    }
  }
  console.error('Wrong iss')
}

export {
  myMSALObj,
  myMSALObjPassword,
  loginRequest,
  tokenRequest,
  silentRequest,
  checkTokenIss
}
